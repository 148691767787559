
  import { Component, Vue } from 'vue-property-decorator'
  import { commonModule, companyModule, filterModule, installationsModule, itemsModule, tasksModule } from '@/store/modules/store-accessor'
  import { getOptimalTextColor, formatOnlyDate, taskStatusToColor, taskStatusToName, taskColor, primaryItemText, pagesExportLimit, templatesFilenameLanguage } from "../helpers/data";
  import TasksFilter from '../components/Filter/TaskFilters.vue'
  import dayjs from 'dayjs';
  import { TimeUtil } from '@/helpers/TimeUtil';
  import FileSaver from 'file-saver';
  import QuickAddAppointment from '@/components/Modals/QuickAdd/QuickAddAppointment.vue';
import TaskModule from '@/store/modules/tasks-module';

  @Component({
    components: { TasksFilter, QuickAddAppointment }
  })
  export default class Tasks extends Vue {

    tableheaders: any [] = [
    { text: '', align: 'left', value: 'category', width: '10px' },
    { text: this.$i18n.t("tag"), align: "start", value: "tag_id" },
    { text: this.$i18n.t("title"), value: "title" },
    { text: this.$i18n.t("client"), value: "name" },
    { text: this.$i18n.t("address"), value: "address" },
    { text: this.$i18n.t("area"), value: "area" },
    { text: this.$i18n.t("type"), value: "action_type_text" },
    { text: this.$i18n.t("userPartner"), value: "assignee_id" },
    { text: this.$t("item"), sortable: true, class: 'row-style', value: 'task_installation_item_id' },
    { text: this.$i18n.t("created_at"), value: "created_at" },
    { text: this.$i18n.t("due_at"), value: "due_at" },
    { text: this.$i18n.t("status"), value: "task_status_id" },
    { text: this.$i18n.t("notes"), value: "notes" },
    ]

    model: any = {
      search: '',
      selected: [] as string[]
    }

    completedTasksStatus = [3, 4, 5];

    isDownloadingTasksPdf = false;

    get tasksLength() {
      return tasksModule.tasks.length
    }

    get searchLabel() {
      return this.$t("tasksSearch")
    }

    get tasks(){
      return tasksModule.tasks;
    }

    get isLoadingTable() {
        return tasksModule.isLoadingTable;
    }

    optimalTextColor(color: string) {
      return getOptimalTextColor(color);
    }

    setStatusColor(status: number) {
        return taskStatusToColor(status);
    }

    setStatusText(status: number) {
        return taskStatusToName(status);
    }


    setDueAt(duet_at: Date) {
        return formatOnlyDate(duet_at)
    }

    setCreatedAt(created_at: Date) {
        return formatOnlyDate(created_at)
    }
  
    async mounted () {
      tasksModule.setIsLoadingTable(true);
      // Default filter for the current day
      const dateFrom = dayjs().startOf('day').toISOString();
      const dateTo = dayjs().endOf('day').toISOString();

      await tasksModule.getTasks(`?date_from=${dateFrom}&date_to=${dateTo}`);
      tasksModule.queryObject.date_from = dateFrom;
      tasksModule.queryObject.date_to = dateTo;
      tasksModule.setFilters(`?date_from=${dateFrom}&date_to=${dateTo}`);

      tasksModule.setSearch(''); // Clear search filter

      tasksModule.setIsLoadingTable(false);
      commonModule.initSnackbar({});
    }

    destroyed() {
      tasksModule.clearTasks();
    }
  
    async openInstallationSideViewOrTaskModal (task: Record<string, any>) {
      if(!task.installation_id) {
        tasksModule.setIsLoadingTable(true);
        tasksModule.unsetCurrentTask();
        await tasksModule.getTaskDetails(task.id);
        commonModule.showModal({ name: 'open-orphan-task-modal' });
        tasksModule.setIsLoadingTable(false);
    } else {
      try {
        tasksModule.setIsLoadingTable(true);
        await installationsModule.getInstallationInfo(task.installation_id);
        tasksModule.setIsLoadingTable(false);
        commonModule.showSideview({ name: 'installations-sideview', payload: { installationId: task.installation_id, tab: 3, taskId: task.id} });
      } catch (error) {
        tasksModule.setIsLoadingTable(false);
      }
    }
  }

    async refreshData() {
      await tasksModule.getTasks(tasksModule.filters);
      await filterModule.getTasksFilters();
    }

    timer: number;

  onSearchChange(val: string) {
    clearTimeout(this.timer)

    this.timer = setTimeout(async () => {
      tasksModule.setIsLoadingTable(true);
      if (val && val.length > 0) {
        tasksModule.setSearch(val);
        tasksModule.queryObject.search = tasksModule.search;
      }
      else {
        tasksModule.setSearch('');
        delete tasksModule.queryObject.search;
      }
      this.$router.replace({ query: tasksModule.queryObject });

      // Check if user selected any of the queries
      const queryIndex = this.$route.fullPath.indexOf('?');
      if (queryIndex >= 0) {
        const query = this.$route.fullPath.substring(queryIndex);
        tasksModule.setFilters(query);
      } else {
        tasksModule.setFilters('');
      }

      await tasksModule.getTasks(tasksModule.filters);
      tasksModule.setIsLoadingTable(false);
    }, 500);
  }


  dateToColor(date: any, task_status_id: number) {
    if([3, 4, 5].includes(task_status_id)) {
      return 'textGrey';
    }

    return taskColor(date)
  }

  formatDateTime (date: Date): string {
    return TimeUtil.formatDateTime(date)
  }

  getItemText(id: number) {
    return primaryItemText(id, itemsModule.allCompanyPrimaryItems);
  }

  downloadPdfLengthCheck() {
    return this.tasksLength > 0 && this.tasksLength <= pagesExportLimit();
  }

  async donwloadTasksPdf() {
    this.isDownloadingTasksPdf = true;
    const utcOffset = dayjs().utcOffset();

    const data = { 'utcOffset': utcOffset, 'language': localStorage.getItem('lang') };
    
    const company = await companyModule.getCompanyInfo();
    const filename = `${templatesFilenameLanguage()}_${company.entity_name}_DfB_tasks_${dayjs().format('YYYY_MM_DD_HH_mm_ss')}.pdf`.replace(/ /g, '_');
    const pdf = await  tasksModule.getTasksPdfExportAction({ query: tasksModule.filters, data: data });
    const file = new File([pdf], filename);
    FileSaver.saveAs(file);

    this.isDownloadingTasksPdf = false;
  }

  }
