
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { commonModule, itemsModule } from '@/store/modules/store-accessor'
import { TimeUtil } from '@/helpers/TimeUtil'

@Component
export default class InstallationsItems extends Vue {
  @Prop() itemsExist!: boolean;
  @Prop() itemsList!: any[];
  @Prop() installation!: any;
  @Prop() item!: any;
  @Prop() subHeaderMsg!: string;
  @Prop() emptyMessage!: string;

  formatDate (date: Date): string {
    return TimeUtil.formatDate(date)
  }

  formatDateTime (date: Date): string {
    return TimeUtil.formatDateTime(date)
  }

  addInstallationItem(){
    commonModule.showModal({ name: 'add-installation-item-modal', payload: { installation: this.installation }})
  }


  editInstallationItem (item: any){
    commonModule.showModal({ name: 'edit-installation-item-modal', payload: { installation_item: item, installation: this.installation }})
  }

  deleteInstallationItem (item: any){
    commonModule.showModal({ name: 'delete-installation-item-modal', payload: { installation_item: item, installation: this.installation }})
  }
}
