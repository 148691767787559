<template>
  <v-container fluid >
    <v-row class="mb-1">
      <v-col cols="6" class="pl-0">
          <v-btn
            icon
            small
            color="textColor"
            class="refresh"
            @click="refreshData()"
            :loading="dataLoading"
            >
            <v-icon small>mdi-autorenew</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="6" class="text-right pr-0">
          <v-select
          @change="calculateDateRange"
          class="main-chart-select dashboard-main-select textColor--text"
          color="textColor"
          v-model="selectTimePeriodOption"
          dense
          flat
          single-line
          hide-details
          :items="dashboardTimePeriodOptions"
          item-text="text"
          return-object
          underlined
          :label="$t('selectTimePeriod')"
          ></v-select>
      </v-col>
    </v-row>
    <div class="dashboard-page">
      <v-row class="mt-1">
        <v-row justify="space-between">
          <v-col class="d-flex" lg="3" md="12" sm="12">
            <v-card class="pb-12 flex-grow-1">
              <v-card-title class="pa-6 pb-10">
                <v-row no-gutters>
                  <v-col cols="7" sm="4" md="4" lg="5" class="d-flex align-center">
                    <p>{{ $t("appointments") }}</p>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text class="pa-6 pb-10">
                <v-row>
                  <v-col>
                    <v-sheet class="dashboard">
                      <div>
                        <ApexChart v-if="apexLoading" type="bar" height="450" :options="appointmentsBarChartOptions" :series="dashboardAppointmentsBar"/>
                    </div>
                    </v-sheet>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col lg="9" md="12" sm="12">
            <v-card class="pb-0 pb-lg-0 fill-height">
              <v-card-title>
                <p>{{ $t("appointmentsList") }}</p> <v-spacer></v-spacer>
                </v-card-title>
                <div class="d-flex flex-row-reverse mb-6">
                  <v-col class="pb-0" cols="3">
                    <v-text-field v-model="appointments_search" append-icon="mdi-magnify" v-bind:label="$t('search')" single-line hide-details/>
                  </v-col>
                </div>
              <DashboardTodaysAppointmentsTable :isLoading="dashboardListTableLoading" :headers="appointments_headers" :items="dashboardAppointments.appointments"
                :search="appointments_search"/>
            </v-card>
          </v-col>
          <v-col lg="3" md="12" sm="12">
          <DashboardCardPieChart :title="$t('appointmentsBreakdown')" :chart_options="actionsBreakdownPie.options"
            :chart_series="actionsBreakdownPie.options.series" :chartHeight="300" :colWidth="12"></DashboardCardPieChart>
          </v-col>
          <v-col lg="9" md="12" sm="12">
            <v-card class="pb-0 pb-lg-0 fill-height">
              <v-card-title class="pa-6 pb-4">
                <p>{{ $t("open_issues") }}</p>
                <v-spacer></v-spacer>
              </v-card-title>
              <div class="d-flex align-end justify-space-between mb-6">
                <v-col class="pb-0" cols="6">
                    <DashboardPendingTasksFilter />
                </v-col>
                <v-col class="pb-0" cols="3">
                  <v-text-field v-model="pendingTasks_search" append-icon="mdi-magnify" v-bind:label="$t('search')" single-line hide-details />
                </v-col>
              </div>
              <DashboardPendingTasksTable :headers="pendingTasks_headers" :isLoading="dashboardPendingTasksTableLoading"
                :items="pendingTasks" :search="pendingTasks_search"/>
            </v-card>
          </v-col>
        </v-row>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import ApexChart from "vue-apexcharts";
import config from "@/config";
import { commonModule, dashboardModule, filterModule, companyModule, itemsModule } from '@/store/modules/store-accessor'
import DashboardTodaysAppointmentsTable from "../components/Tables/DashboardTodaysAppointmentsTable.vue";
import DashboardPendingTasksTable from "../components/Tables/DashboardPendingTasksTable.vue";
import DashboardCardBarChart from "../components/Cards/DashboardCardBarChart.vue";
import DashboardCardPieChart from "../components/Cards/DashboardCardPieChart.vue";
import TheLoadingIndicator from '@/components/TheLoadingIndicator.vue'
import DashboardPendingTasksFilter from '../components/Filter/DashboardPendingTasksFilter.vue'

import dayjs from 'dayjs';
import _ from 'lodash';
import { sortTableDates } from "@/helpers/data";


@Component({
  components: {
    ApexChart,
    DashboardCardBarChart,
    DashboardCardPieChart,
    DashboardTodaysAppointmentsTable,
    TheLoadingIndicator,
    DashboardPendingTasksTable,
    DashboardPendingTasksFilter
  },
})
export default class Dashboard extends Vue {

  selectTimePeriodOption = {};
  selectTasksFilterOption = {};

  async calculateDateRange() {
    let dateFrom = dayjs().startOf('day').toISOString()//IsoString gives time in ISO string time in browser local time
    let dateTo = dayjs().endOf('day').add(this.selectTimePeriodOption.days, 'days').toISOString()//IsoString gives time in ISO string time in browser local time

    dashboardModule.setFilters(`?date_from=${dateFrom}&date_to=${dateTo}`);
    await dashboardModule.getDashboardAppointmentsAction(dashboardModule.filters);
    await dashboardModule.getDashboardAppointmentsBarsAction(dashboardModule.filters);

    let data_series = [];
    let data_labels = [];
    let data_colors = [];

    for (let i = 0; i < this.dashboardAppointments.appointmentsBreakdown.length; i++) {
      data_colors.push(
        this.dashboardAppointments.appointmentsBreakdown[i].type_color
      );
      data_labels.push(
        this.dashboardAppointments.appointmentsBreakdown[i].type_name
      );
      data_series.push(parseInt(this.dashboardAppointments.appointmentsBreakdown[i].count));
    }

    this.actionsBreakdownPie.options = {
      ...this.actionsBreakdownPie.options,
      ...{
        colors: data_colors,
        labels: data_labels,
        series: data_series,
      },
    };
  }

  appointments_search = "";
  appointments_headers = [
    {
      text: this.$i18n.t("client"),
      align: "start",
      value: "client_name",
    },
    { text: this.$i18n.t("type"), value: "type_name" },
    { text: this.$i18n.t("user"), value: "assignee_names" },
    { text: this.$i18n.t("address"), value: "address" },
    { text: this.$i18n.t("area"), value: "area" },
    { text: this.$i18n.t("scheduled_at"), value: "scheduled_start", sort: (a, b) => sortTableDates(a, b) },
    { text: this.$i18n.t("projected_duration"), value: "projected_duration" },
    { text: this.$i18n.t("status"), value: "appointment_status_id" },
  ];

  pendingTasks_search = "";
  pendingTasks_headers = [
    { text: '', align: 'left', value: 'category' },
    { text: this.$i18n.t("title"), value: "title" },
    { text: this.$i18n.t("type"), value: "action_type_text" },
    { text: this.assigneeLabel, value: "assignee_id" },
    { text: this.$i18n.t("due_at"), value: "due_at" },
    { text: this.$i18n.t("client"), value: "name" },
    { text: this.$i18n.t("address"), value: "address" },
    { text: this.$t("item"), sortable: true, class: 'row-style', value: 'primary_items' },
    { text: this.$i18n.t("notes"), value: "notes" },
  ];

  dashboardTimePeriodOptions = [
    { text: this.$i18n.t("daily"), days: 0 },
    { text: this.$i18n.t("currentWeek"), days: 7 },
    { text: this.$i18n.t("twoWeeksFromCurrent"), days: 14 },
    { text: this.$i18n.t("fourWeeksFromCurrent"), days: 28 }
  ];

  tasksFilterOptions = [
    { text: this.$i18n.t("today"), days: 0 },
    { text: this.$i18n.t("week"), days: 7 },
    { text: this.$i18n.t("all"), days: null },
  ];

  actionsBreakdownPie = {
    options: {
      dataLabels: {
        enabled: true,
      },
      colors: [],
      labels: [],
      series: [],
      plotOptions: {
        pie: {
          customScale: 1.05,
        }
      },
      legend: {
        position: 'bottom',
        offsetY: 0
      }
    },
  };

  appointmentsBarChartOptions = {
      colors : [this.$vuetify.theme.currentTheme.success, this.$vuetify.theme.currentTheme.info, this.$vuetify.theme.currentTheme.appointmentTypeError],
      chart: {
        stacked: true,
        width: "100%",
        toolbar: {
          show: false
        }
      },
    dataLabels: {
      enabled: false,
    },
    yaxis: 
      {
        labels: {
          formatter: function(val) {
            return val.toFixed(0);
          }
        }
      }
    ,
    xaxis: {
      type: 'datetime',
      labels: {
        format: 'dd/MM'
      }
    },
    legend: {
      onItemClick: {
          toggleDataSeries: false
      },
    },
    tooltip: {
      y: {
        formatter: (val) => {
         if(val) {
           return val.toFixed(0)
         }
        }
      },
    }
  }

  apexLoading = false;

  get dashboardAppointments() {

    if(companyModule.company?.preferences?.job_description) {
      if(!(this.appointments_headers.some(obj => obj.value === 'description'))) {
        this.appointments_headers.unshift({ text: this.$t('description'), sortable: true, class: 'row-style', value: 'description' });
      }
    }

    if(!companyModule.company?.preferences?.job_description) {
      if((this.appointments_headers.some(obj => obj.value === 'description'))) {
        this.appointments_headers.shift({ text: this.$t('description'), sortable: true, class: 'row-style', value: 'description' });
      }
    }

    this.appointments_headers = _.uniqWith(this.appointments_headers, _.isEqual)

    return dashboardModule.dashboardAppointments;
  }

  get dashboardAppointmentsBar() {
    return dashboardModule.dashboardAppointmentsBar;
  }

  get pendingTasks() {
    return dashboardModule.dashboardPendingTasks;
  }

  get dataLoading () {
    return dashboardModule.isLoadingAppointmentsList || dashboardModule.isLoadingPendingTasks;
  }

  get dashboardListTableLoading() {
    return dashboardModule.isLoadingAppointmentsList;
  }

  get dashboardPendingTasksTableLoading() {
    return dashboardModule.isLoadingPendingTasks;
  }

  get assigneeLabel() {
    if(companyModule.company?.modules?.includes('DFB_PORTAL_PARTNERS')) {
      return this.$i18n.t('userPartner');
    }
    else {
      return this.$i18n.t('user');
    }
  }

  async mounted () {
    setTimeout(() => {
      this.apexLoading = true;
    });


    companyModule.getCompanyInfo();
    this.selectTimePeriodOption = this.dashboardTimePeriodOptions[0];
    this.selectTasksFilterOption = this.tasksFilterOptions[2];

    commonModule.initSnackbar({});
    /*
      TODO: Handle installations properly when UX is clear.
      */

    // Today date range
    const dateFrom = dayjs().startOf('day').toISOString()//IsoString gives time in ISO string time in browser local time
    const dateTo = dayjs().endOf('day').toISOString()//IsoString gives time in ISO string time in browser local time
    const today = [dateFrom, dateTo];

    dashboardModule.setFilters(`?date_from=${today[0]}&date_to=${today[1]}`);
    dashboardModule.setTaskFilters('');
    await dashboardModule.getDashboardAppointmentsAction(dashboardModule.filters);

    await filterModule.getDashboardTasksFilters();
    dashboardModule.getDashboardPendingTasks('');
    
    await dashboardModule.getDashboardAppointmentsBarsAction(dashboardModule.filters);
    itemsModule.getAllCompanyPrimaryInventoryItems();

    // Loaders

    // Appointments Chart Bar - End

    // Appointment types - Start
    let data_series = [];
    let data_labels = [];
    let data_colors = [];

    for (let i = 0; i < this.dashboardAppointments.appointmentsBreakdown.length; i++) {
      data_colors.push(
        this.dashboardAppointments.appointmentsBreakdown[i].type_color
      );
      data_labels.push(
        this.dashboardAppointments.appointmentsBreakdown[i].type_name
      );
      data_series.push(parseInt(this.dashboardAppointments.appointmentsBreakdown[i].count));
    }

    this.actionsBreakdownPie.options = {
      ...this.actionsBreakdownPie.options,
      ...{
        colors: data_colors,
        labels: data_labels,
        series: data_series,
      },
    };
    // Appointment types - End


  }

  destroyed() {
    dashboardModule.clearDashboardData();
  }

  async refreshData() {

    dashboardModule.getDashboardAppointmentsAction(dashboardModule.filters);
    await filterModule.getDashboardTasksFilters();
    dashboardModule.getDashboardPendingTasks(dashboardModule.taskFilters);
    await dashboardModule.getDashboardAppointmentsBarsAction(dashboardModule.filters);
  }
}
</script>

<style lang="scss">
@import "../styles/app";

.dashboard-page {
  .page-title {
    padding-bottom: 20px;
    @include media-breakpoint-up(sm) {
      padding-bottom: 0;
    }
  }
  .apexcharts-legend-series {
    padding: 2px 5px 3px;
    display: flex;
    align-items: center;
    .apexcharts-legend-marker {
      margin-right: 8px;
    }
  }
  .main-chart-select {
    font-size: 1rem;
    margin-right: 10px;
    max-width: 300px;
    fieldset {
      border-width: 1px !important;
      border-color: $text-grey !important;
      color: $text-grey !important;
    }
    .v-select__selection {
      color: $text-color !important;
    }
  }
  .v-card {
    .v-card__text {
      .subtext {
        font-size: 1.142rem;
        font-weight: 400;
        color: $title-grey;
      }
      .subtext-index {
        font-size: 11px;
        color: $text-color;
        font-weight: 400;
      }
    }
  }
}

.dashboard-main-select {
  width: 450px;
 float: right;
}

.v-menu__content {
  box-shadow: $card-shadow !important;
  .v-list-item__content .v-list-item__title {
    font-size: 1rem;
    font-weight: 400;
  }
}
.button-shadow {
  box-shadow: $button-shadow;
}

.dashboard {
  position: relative;
}
</style>
