
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { commonModule, installationsModule, clientsModule } from '@/store/modules/store-accessor'
import { TimeUtil } from '@/helpers/TimeUtil'

@Component
export default class ClientsSideviewInstallationsTab extends Vue {
 @Prop() client: Record<string, any>;

  formatDate (date: Date): string {
    return TimeUtil.formatDateTime(date)
  }

  get installationsExist() {
    return this.client.installations.length > 0
  }

  get clientInstallations() {
    return this.client.installations;
  }

  async openInstallation(installationId: any) {
    await installationsModule.getInstallationInfo(installationId);
    clientsModule.unsetCurrentClient();
    commonModule.showSideview({ name: 'installations-sideview' , payload: { installationId: installationId} })
  }

};
