import { render, staticRenderFns } from "./ClientsSideviewInstallationsTab.vue?vue&type=template&id=1e22db2c&scoped=true&class=fill-height&"
import script from "./ClientsSideviewInstallationsTab.vue?vue&type=script&lang=ts&"
export * from "./ClientsSideviewInstallationsTab.vue?vue&type=script&lang=ts&"
import style0 from "./ClientsSideviewInstallationsTab.vue?vue&type=style&index=0&id=1e22db2c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e22db2c",
  null
  
)

export default component.exports