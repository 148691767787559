
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { statsModule, commonModule, installationsModule, actionTypesModule } from "@/store/modules/store-accessor";
import KeycloakSession from '@/helpers/keycloak-session'

@Component
export default class Sidebar extends Vue {

  @Prop()
  source: string;

  quickAddMenu = false;

  get kcSession() {
    return new KeycloakSession();
  }

  get quickAddLoader () {
    return this.quickAddBtnLoading || this.quickAddTaskLoading || this.quickAddCompanyExpenseLoading;
  }

  sidebarWidth =  240
  sidebarMinWidth = 96
  quickAddBtnLoading = false;
  quickAddTaskLoading = false;
  quickAddCompanyExpenseLoading = false;



  items= [
    {
      title: this.$i18n.t("dashboard"),
      icon: "mdi-home",
      link: "/dashboard",
      permissions: ["admin","editor"],
    },
    {
      title: this.$i18n.t("clients"),
      icon: "mdi-contacts-outline",
      link: "/clients",
      permissions: ["admin","editor"],
    },
    {
      title: this.$i18n.t("installations"),
      icon: "mdi-home-city-outline",
      link: "/installations",
      permissions: ["admin","editor"],
    },
    {
      title: this.$i18n.t("appointments"),
      icon: "mdi-clipboard-list-outline",
      link: "/appointments",
      permissions: ["admin","editor"],
    },
    {
      title: this.$i18n.t("jobs"),
      icon: "mdi-tools",
      link: "/jobs",
      permissions: ["admin","editor"],
    },
    {
      title: this.$i18n.t("tasks"),
      icon: "mdi-format-list-numbered-rtl",
      link: "/tasks",
      permissions: ["admin","editor"],
    },
    {
      title: this.$i18n.t("items"),
      icon: "mdi-water-boiler",
      link: "/items",
      permissions: ["admin","editor"],
    },
    {
      title: this.$i18n.t("map"),
      icon: "mdi-google-maps",
      link: "/map",
      permissions: ["admin","editor"],
    },
    {
      title: this.$i18n.t("calendar"),
      icon: "mdi-calendar",
      link: "/calendar",
      permissions: ["admin","editor"],
    },
    {
      title: this.$i18n.t("myCompany"),
      icon: "mdi-office-building-cog-outline",
      link: "/my-company",
      permissions: ["admin","editor"],
    }
  ]

  hasAccess(permissions: []) {
    return permissions.some(el => this.kcSession.userHasRole(el));
  }

  get DRAWER_STATE() {
    return statsModule.drawerState;
  }

  async quickAdd (){
    this.quickAddBtnLoading = true;
    await installationsModule.getInstallationNextTagId();
    commonModule.getDurationCombo();
    this.quickAddBtnLoading = false;
    commonModule.showModal({ name: 'quick-add-appointment-modal' })
  }

  async quickAddTask () {
    this.quickAddTaskLoading = true;
    await actionTypesModule.getActionTypesCombo();
    commonModule.showModal({ name: 'quick-add-task-modal' });
    this.quickAddTaskLoading = false;
  }

  async quickAddCompanyExpense () {
    this.quickAddCompanyExpenseLoading = true;
    await commonModule.getPaymentTypeCombo();
    commonModule.showModal({ name: 'add-company-expense-modal' });
    this.quickAddCompanyExpenseLoading = false;
  }

}

