
import { Component, Vue, Prop } from 'vue-property-decorator'
import { commonModule, clientsModule, installationsModule, appointmentsModule, tasksModule, statsModule, jobsModule } from '@/store/modules/store-accessor'
import LoadingIndicator from '@/components/TheLoadingIndicator.vue'
import ClientsSideviewGeneralTab from '@/components/sideviews/clients/ClientsSideviewGeneralTab.vue'
import ClientsSideviewInstallationsTab from '@/components/sideviews/clients/ClientsSideviewInstallationsTab.vue'
import ClientsSideviewPaymentsTab from '@/components/sideviews/clients/ClientsSideviewPaymentsTab.vue'
import { TimeUtil } from '@/helpers/TimeUtil'
import TheMiniLoader from '@/components/TheMiniLoader.vue'

@Component({
  components: {
    LoadingIndicator,
    ClientsSideviewGeneralTab,
    ClientsSideviewInstallationsTab,
    ClientsSideviewPaymentsTab,
    TheMiniLoader,
  }
})
export default class ClientsSideview extends Vue {
  @Prop()
  loading!: boolean;

  @Prop()
  payload!: any;

  tab = 0;

  isEditing!: boolean;

  async mounted() {
    installationsModule.getInstallationsByIdAction(this.payload['clientId']);
    commonModule.getContactTypeCombo();
  }

  async hideSideview () {
    commonModule.hideSideview();
  }

  get currClientId () {
    return this.payload['clientId'];
  }

  get currClient () {
    return clientsModule.currentClient;
  }

  get isLoading() {
    return clientsModule.isLoadingSideView;
  }

  clientIconSrc() {
    if (this.currClient.info.is_business) {
      return require('@/assets/img/company_icon.png');
    } else {
      return require('@/assets/img/client_icon.png');
    }
  }

  formatDate (date: Date): string {
    return TimeUtil.formatDateTime(date)
  }

  updated() {
    this.isEditing = false;
  }

  refreshData () {
    clientsModule.setIsLoadingSideView(true);
    clientsModule.getClientsInfo(this.currClientId)
  }
  

  async editClient() {
    await clientsModule.getClientsInfo(this.currClientId)
    commonModule.showModal({ name: 'edit-client-modal', payload: { client: this.currClient.info, contacts: this.currClient.contacts, view: "clientsSideview" }})
  }

  deleteClient() {
    commonModule.showModal({ name: 'delete-client-modal', payload: { client: this.currClient.info }} )
  }

  close () {
    clientsModule.unsetCurrentClient();

    switch(this.$route.name) {
      case 'Dashboard':
        tasksModule.getPendingTasks();
        statsModule.getStatsAction('');
        break;
        case 'Installations':
        installationsModule.getInstallationsAction(installationsModule.filters);
        break;
        case 'Jobs':
        jobsModule.getJobs(jobsModule.filters);
        break;
        case 'Map':
        installationsModule.getInstallationsAction(installationsModule.filters);
        break;
        case 'Calendar':
        appointmentsModule.getCalendarAppointmentsAction(appointmentsModule.filters);
        break;
        case 'Clients':
          clientsModule.getClientsAction(clientsModule.filters);
          break;
    }

    this.hideSideview()
  }

};
