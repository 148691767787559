
  import { Component, Vue, Prop } from 'vue-property-decorator'
  import { commonModule, dashboardModule, tasksModule } from '@/store/modules/store-accessor'
  
  @Component
  export default class CompleteTaskModal extends Vue {
    @Prop()
    payload!: any;

    isCompletingTask = false;
  
    async hideModal () {
      if(this.payload.isOrphanTask) {
        commonModule.hideSecondModal();
        commonModule.hideModal();
      } else {
        commonModule.hideModal();
      }
    }
  
    async mounted() {
      commonModule.initSnackbar({});
    }
  
    doComplete () {
        this.isCompletingTask = true;
        tasksModule.completeTask(this.payload.task)
        tasksModule.getTaskDetails(this.payload.task.id);
        if(this.$route.name === 'Dashboard') {
          dashboardModule.getDashboardPendingTasks(dashboardModule.taskFilters);
        }
        this.hideModal()
    }
  };
