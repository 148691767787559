
import { Component, Vue, Prop } from 'vue-property-decorator'
import { commonModule, jobsModule, actionTypesModule, installationsModule, itemsModule, itemTypesModule, companyModule } from '@/store/modules/store-accessor'
import dayjs from 'dayjs';
import { isDecimalNumber, getOptimalTextColor, isNumber } from "@/helpers/data";
import _ from 'lodash';
import AssigneeCombo from '@/components/Combobox/AssigneeCombo.vue';
import utc from 'dayjs/plugin/utc';
import { TimeUtil } from '@/helpers/TimeUtil';

dayjs.extend(utc);

@Component({
  components: {
    AssigneeCombo,
  },
})
export default class AddJobModal extends Vue {
  @Prop()
  payload!: any;

  isAddingJob = false;

  dateMenu = false;
  scheduledTimeMenu= false;
  addMaterials = false;
  scheduled_start_time = this.formatCurrentTime(new Date);
  addExpenses = false;

  payloadAssignee = {} as any;
  loadingInstallationPrimaryItems = true;
  
  jobModel = {
   created_at: this.formatDate(new Date),
   created_at_date: dayjs().format('YYYY-MM-DD'),
   created_at_time: dayjs().format('HH:mm'),
   type_id: [] as any[],
   materials: [{
        id: Math.random() * -1, //Need Random and non-integer and also negative, for this to work with the backend properly
        cost: 0, //This needs to be initialized not null for the reduce to work
        description: '',
        notes: ''
    }] as any,
   assignee_id: "",
   installation_id: "",
   is_complete: false,
   notes: this.payload.notes || '',
   payment_amount: 0,
   is_paid: false,
   radioValue: "user",
   payment_type: this.paymentTypesCombo[1],
   task_id: this.payload.task_id,
   partner_id: this.payload.partner_id || '',
   installation_item_id: this.getItemText(this.payload.installation_item_id),
   expenses: [{
    id: Math.random() * -1, //Need Random and non-integer and also negative, for this to work with the backend properly
    cost: 0,
    notes: '',
  }] as any,
   description: '',
  }

  photoModel = {
    add_photo: false,
    photo: null,
    url: null,
    notes:  '',
  }

  typeRule= [
    (val: any) => (val || '').length > 0 || this.$i18n.t("actions_required"),
  ]
  userRule= [
    (val: any) => !!val || this.$i18n.t("user_required"),
  ]
  notesRule= [
    (val: any) => {
      if(val != null) return val.length <= 1000 || this.$i18n.t("form_notes_length_rule");
      else return true;
    },
  ]
  expenseNotesRule = [
    (val: any) => {
      if(val != null) return val.length <= 30 || this.$i18n.t("expenseNotesLengthRule");
    }
  ]
  materialsNotesRule = [
    (val: any) => {
      if(val != null) return val.length <= 1000 || this.$i18n.t("notesLengthRule");
      else return true;
    },
  ]
  actionNotesRule = [
  (val: any) => {
      if(val != null) return val.length <= 1000 || this.$i18n.t("notesLengthRule");
      else return true;
    },
  ]
  paymentTypeRule= [
    (val: any) => (val || '').id > 0 || this.$i18n.t("payment_type_required"),
  ]
  scheduledTimeRule = [
    (val: any) => !!val || this.$i18n.t("jobScheduledTimeRequired"),
  ]
  photoRules = [
    (val: any) => !!val || this.$t('photoRequired'),
  ]
  photoNotesRule = [
    (val: any) => val.length <= 50 || this.$i18n.t("notesForPhotoRule"),
  ]
  costRule:any = [
    (val: any) => {
        if (val === '' || isNaN(val)) {
          return this.$i18n.t("cost_required");
        }
        return val >= 0 || this.$i18n.t("cost_required");
      },
  ]
  
  descriptionRule= [
      (val: any) => (val == null || ((val?.text?.length <= 80) || val?.length <=80 )) || this.$i18n.t("client_form_description_length_rule"),
  ]

  scheduledDateRule = [
    (val: any) => !!val || this.$i18n.t("jobScheduledDayRequired"),
  ]

  jobDescriptionRule = [
    (val: any) => val.length <= 80 || this.$i18n.t("installationDescriptionLengthRule"),
  ]

  actualCostRule:any =[]
  paymentRule:any =[]

  nextEventRule = [
    (val: any) => !!val || this.$i18n.t("nextEventRuleText"),
  ]

  reminderRule = [
    (val: any) => val !== '' && val >= 0 || this.$i18n.t("reminderRuleText"),
  ]

  reminderOptionRule = [
    (val: any) => ((!!val || val == '') && val.text.length > 0) || this.$i18n.t("reminderOptionRuleText"),
  ]

  get periodicCombo () {
    return commonModule.periodicCombo;
  }

  get jobTotalCost() {    
    let actionTotal =  this.jobModel.type_id?.reduce((accumulator: number, item: any) => {return accumulator + (!isNaN(parseFloat(item.cost)) ? parseFloat(item.cost) : 0)}, 0) 
    let materialTotal = this.jobModel.materials?.reduce((accumulator: number, item: any) => {return accumulator + (!isNaN(parseFloat(item.cost)) ? parseFloat(item.cost) : 0)}, 0);

    let total = actionTotal;

    if(this.addMaterials)
      total += materialTotal

    this.paymentRule = [
      (val: any) => {
        if (val === '' || isNaN(val)) {
          return this.$i18n.t("nonZeroPaymentRule");
        }
        return val > 0 || this.$i18n.t("nonZeroPaymentRule");
      },
      (val: any) => val <= total || this.$i18n.t("actualCostRule")
    ]

    return total
  }

  async setActionTypeFromId(action_type_id: any) {
    const foundType = actionTypesModule.actionTypesCombo.find((item: any) => item.id === action_type_id);
    if(foundType) {
      this.jobModel.type_id.push({
        text: foundType.text, color: foundType.color, cost: foundType.cost, id: foundType.id,
        is_periodic: foundType.is_periodic, next_task: foundType.next_task,
        reminder_option_value: foundType.reminder_option_value, reminder_option_combo: foundType.reminder_option_combo,
        periodic_days: foundType.periodic_days, periodic_option: foundType.periodic_option, reminder_days: foundType.reminder_days,
      }) as any;
    }
  }

  async created() {
    await actionTypesModule.getActionTypesCombo();
    await this.setActionTypeFromId(this.payload.action_type_id);
  }

  get actionsCombo () {
      return actionTypesModule.actionTypesCombo;
  }

  get paymentTypesCombo () {
    return commonModule.paymentTypeCombo;
  }

  get itemTypesCombo () {
    return itemTypesModule.itemTypesCombo;
  }

  get isLoadingInstallationPrimaryItems() {
    return this.loadingInstallationPrimaryItems;
  }

  async hideModal () {
    commonModule.hideModal();
  }

  optimalTextColor(color: string) {
    return getOptimalTextColor(color);
  }

  onlyNumbers(evt: any) {
      isNumber(evt);
  }

  async mounted() {
    this.payloadAssignee = this.getAssigneId(this.payload.assignee);
    commonModule.initSnackbar({});
    await actionTypesModule.getActionTypesCombo();
    await commonModule.getPaymentTypeCombo();
    await commonModule.getItemTypeCombo();
    await itemTypesModule.getItemTypesCombo();
    await commonModule.getPersonnelPartnersCombo();
    await itemsModule.getAllInventoryItems('');
    await commonModule.getPeriodicCombo(1);
    this.loadingInstallationPrimaryItems = true;
    await itemsModule.getInstallationPrimaryInventoryItems(this.payload.installation.info.id);

    if(this.payload.installation_item_id) {
      this.jobModel.installation_item_id = this.getItemText(this.payload.installation_item_id);
    } else {
      (this.jobModel.installation_item_id as any) = this.defaultInstallationItem();
    }
    this.loadingInstallationPrimaryItems = false;
  }

  defaultInstallationItem() {
    if(this.installtionPrimaryItems && this.installtionPrimaryItems.length === 1) {
      return  { primary_items: this.installtionPrimaryItems[0].primary_items, id: this.installtionPrimaryItems[0].id};
    }
    return { primary_items: '', id: 0};
  }

  updateAssignee(assignee: any) {
    this.jobModel.assignee_id = assignee;
  }

  PreviewPhoto() {
    if(this.photoModel.photo !== undefined && this.photoModel.photo !== null) {
      (this.photoModel.url as any) = URL.createObjectURL(this.photoModel.photo);
    } else {
      // Clear photo preview
      (this.photoModel.add_photo as any) = null;
      (this.photoModel.url as any) = '';
      this.photoModel.add_photo = true; // Keep the checkbox checked
    }
  }

  getAssigneId(name: string) {
    const combo: any = commonModule.personnelPartnersCombo;
    const foundItem = combo.find((item: any) => item.name === name);
    if(foundItem) {
      return { name: foundItem.name, id: foundItem.id };
    } else {
      return ''
    }
  }
    
  get installtionPrimaryItems() {
    return itemsModule.installationPrimaryItems;
  }

  get disablePrimaryItems() {
    return this.loadingInstallationPrimaryItems || this.installtionPrimaryItems.length === 0;
  }

  repayment() {
    this.jobModel.payment_amount = this.jobTotalCost
  }

  addMaterial() {
    let newMaterial = {
        id: Math.random() * -1,
        cost: 0, //This needs to be initialized not null for the reduce to work
        description: '',
        notes: ''
    } as any

    this.jobModel.materials.push(newMaterial);
  }


  deleteMaterial(index: number) {
    if (this.jobModel.materials.length > 1) {
      this.jobModel.materials.splice(index, 1);
    }
  }

  addExpense() {
    let newExpense = {
        id: Math.random() * -1,
        cost: 0,
        notes: ''
    } as any

    this.jobModel.expenses.push(newExpense);
  }

  deleteExpense(index: number) {
    if (this.jobModel.expenses.length > 1) {
      this.jobModel.expenses.splice(index, 1);
    }
  }

  async doAddJob () {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        this.isAddingJob = true;
        let job = _.cloneDeep(this.jobModel);
        this.typeRule = [];
        job.installation_id = this.payload.installation.info.id

        job.created_at = dayjs(this.jobModel.created_at_date + " " + this.jobModel.created_at_time).utc().format("DD MMM YYYY HH:mm:ss")

        if(this.addMaterials) {
          job.materials = job.materials.map((material: any) => {
            if (typeof material.description === 'object' && material.description != null) {
              material.description = material.description.text
              return material
            }
            else {
              return material
            }
          })
        }
        else {
          delete job.materials
        }

        if(!this.addExpenses) {
          delete job.expenses;
        }

        job.type_id.map(item => {
          item.due_at =  item.is_periodic ? dayjs(item.next_task).startOf('day').subtract((parseInt(item.reminder_option_value) * item.reminder_option_combo.period), 'days').format('YYYY-MM-DD') : null
        })

        const jobId: any = await jobsModule.createJobWithActions(job)

        if (this.photoModel.add_photo) {
          let formData = new FormData();
          formData.append("image", (this.photoModel.photo as any));
          formData.append("notes", (this.photoModel.notes as any));
          formData.append("job_id", jobId.id);

          await installationsModule.addInstallationPhoto({installationId: job.installation_id, photo: formData});
        }
        installationsModule.getInstallationInfo(job.installation_id);
        this.hideModal()
    }  
  }

  onDateChange(ev: any) {
    this.jobModel.type_id.map(item => {
          item.next_task =  item.is_periodic ? dayjs(ev).startOf('day').add(item.periodic_days, 'days').format('YYYY-MM-DD') : null
        })
  }

  onActionTypeChange(ev: any) {
    this.jobModel.type_id.map(item => {
          item.next_task =  item.is_periodic ? dayjs(this.jobModel.created_at_date).startOf('day').add(item.periodic_days, 'days').format('YYYY-MM-DD') : null
        })
  }

  onlyDecimalNumbers(evt: any) {
    isDecimalNumber(evt);
  }

  formatCurrentTime(date: Date): string {
    return TimeUtil.formatTimeOnly(date);
  }

  formatDate (date: Date): string {
    return TimeUtil.formatDateOnly(date)
  }

  getItemText(ids: number) {
    const idArray = Array.isArray(ids) ? ids : [ids];
    const arr: any = itemsModule.allCompanyPrimaryItems

    for (let i = 0; i < arr.length; i++) {
      if ((Array.isArray(arr[i].id) && arr[i].id.some((item: any) => idArray.includes(item))) || (!Array.isArray(arr[i].id) && idArray.includes(arr[i].id))) {
       (itemsModule.installationPrimaryItems as any).push({ primary_items: arr[i].primary_items, id: ids })
        return { primary_items: arr[i].primary_items, id: ids };
      }
    }
    return { primary_items: '', id: 0 };
  }

  get jobDescriptionIsEnabled() {
    return (companyModule.company?.preferences as any)?.job_description;
  }

};
