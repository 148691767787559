
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { commonModule, companyModule, installationsModule, itemsModule } from '@/store/modules/store-accessor'
import { isDecimalNumber, isGasCompany } from "@/helpers/data";
import dayjs from 'dayjs';

@Component
export default class AddInstallationItemModal extends Vue {
  @Prop()
  payload!: any;

  isAddingItem = false;

  menu= false;

  itemModel = {
   selected_item: {id: 0, name: "", item_type_id: 0, warranty: ''},
   installed_at: dayjs().format('YYYY-MM-DD'),
   is_primary: true,
   serial_number: "",
   is_active: true,
   cost: "",
   installation_placement_type_id: {id: null, installation_type: ""},
   fuel_type_id: {id: null, fuel_type: ""},
   notes:"",
   warranty: "",
   installation_id: this.payload.installation.info.id
  }

  @Watch('itemModel.selected_item')
    onSelectedItemChange() {
      this.itemModel.warranty = this.itemModel.selected_item.warranty;
    }

  get installationItemsCombo () {
    return itemsModule.installationItems;
  }

  get fuelTypeCombo () {
    return commonModule.fuelTypeCombo;
  }

  get placementTypeCombo () {
    return commonModule.placementTypeCombo;
  }

  installationItemRule= [
    (val: any) => (val || '').name.length > 0 || this.$i18n.t("installation_item_required"),
  ]
  notesRule= [
    (val: any) => {
      if(val != null) return val.length <= 1000 || this.$i18n.t("notesLengthRule");
      else return true;
    },
  ]
  serialNoRule= [
    (val: any) => val.length <= 50 || this.$i18n.t("form_serialNumber_length_rule"),
  ]
  costRule = [
    (val: any) => val >= 0 ||this.$i18n.t('nonNegativeCostRule'),
  ]

  async hideModal () {
    commonModule.hideModal();
  }

  async mounted() {
    commonModule.initSnackbar({});
    await commonModule.getPersonnelCombo();
    await commonModule.getActionCombo();
    await itemsModule.getAllInventoryItems('');
    await commonModule.getFuelTypeCombo();
    await commonModule.getPlacementTypeCombo();
  }

  onlyDecimalNumbers(evt: any) {
    isDecimalNumber(evt);
  }

  doAdd () {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      this.isAddingItem = true;
      (this.itemModel.selected_item as any).model = (this.itemModel.selected_item as any).model ?? '-'
      installationsModule.addInstallationItem(this.itemModel)
      itemsModule.getInstallationPrimaryInventoryItems(this.itemModel.installation_id);
      itemsModule.getAllCompanyPrimaryInventoryItems();
      this.hideModal()
    }
  }

  isNumber(evt: any) {
      evt = evt || window.event;
      let charCode = evt.which || evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
      return false;
  }

  isGasCompany() {
    return isGasCompany(companyModule.company)
  }

};
