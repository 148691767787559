
  import { Component, Vue, Prop } from 'vue-property-decorator';
  import { commonModule, installationsModule } from '@/store/modules/store-accessor';
  import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
  import { Icon } from "leaflet";

  Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
  });

@Component({
  components: {
    LMap, LTileLayer, LMarker
  }
})
  export default class AddInstallationAddressModal extends Vue {

    @Prop()
    payload!: any;
   
    zoom = 16;
    url = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
    // Default coordinatates: Athens, Greece
    center = [37.983810, 23.727539];
    markerCoords = [37.983810, 23.727539];
    lat = this.markerCoords[0];
    long = this.markerCoords[1];

    async hideModal () {
      commonModule.hideSecondModal();
    }

    async mounted() {
      commonModule.initSnackbar({});
      this.center = this.payload.coordinates.latitude && this.payload.coordinates.longitude ? [this.payload.coordinates.latitude, this.payload.coordinates.longitude] : [this.markerCoords[0], this.markerCoords[1]];
      this.markerCoords = this.payload.coordinates.latitude && this.payload.coordinates.longitude ? [this.payload.coordinates.latitude, this.payload.coordinates.longitude] : [this.markerCoords[0], this.markerCoords[1]];
      this.lat = this.markerCoords[0];
      this.long = this.markerCoords[1];
    }

    updateCoordinates() {
      if(parseFloat((this.lat as any)) &&  parseFloat((this.long as any))) {
        this.markerCoords = [parseFloat((this.lat as any)), parseFloat((this.long as any))];
        this.center = [parseFloat((this.lat as any)), parseFloat((this.long as any))];
      }
    }

    async selectCoordinates () {
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
          installationsModule.setNewInstallationCoordinates(this.markerCoords);
        this.hideModal();
    }
  }

    getCoordinates(event: Record<string, any>) {
      this.markerCoords = [event.target._latlng.lat, event.target._latlng.lng];
      this.lat = event.target._latlng.lat;
      this.long = event.target._latlng.lng;
    }

  };
  