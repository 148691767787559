
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { TimeUtil } from '@/helpers/TimeUtil'
import { appointmentStatusToName, appointmentStatusToColor, calcDuration, getOptimalTextColor, primaryItemText } from '@/helpers/data'
import { commonModule, installationsModule, actionTypesModule, itemsModule, companyModule } from '@/store/modules/store-accessor'

@Component
export default class InstallationsSideviewAppointmentsTab extends Vue {
  @Prop() installation: Record<string, any>;
  @Prop() appointmentId: number;

  upcomingAppointmentsExpansionPanel = [0];
  completedAppointmentsExpansionPanel = [-1];

  expansionAppointmentIdIndex = null as number | null;
  expansionCompletedAppointmentIdIndex = null as number | null;

  mounted() {
    commonModule.getPersonnelCombo();
    commonModule.getPersonnelPartnersCombo();
    commonModule.getActionCombo();
    commonModule.getDurationCombo();
    actionTypesModule.getActionTypesCombo();
    itemsModule.getAllCompanyPrimaryInventoryItems();

    this.expansionAppointmentIdIndex =  this.upcomingAppointments.findIndex((i: any) => i.id == this.appointmentId); //This is needed to find the index in the expansion panel
    this.expansionCompletedAppointmentIdIndex =  this.completedAppointments.findIndex((i: any) => i.id == this.appointmentId); //This is needed to find the index in the expansion panel
    
    if(this.expansionCompletedAppointmentIdIndex != -1) {
      this.upcomingAppointmentsExpansionPanel = [-1];
      this.completedAppointmentsExpansionPanel = [0];
    }
  }

  @Watch("installation")
  updateAppointments() {
    commonModule.getPersonnelCombo();
    commonModule.getActionCombo();
    commonModule.getDurationCombo();
    commonModule.initSnackbar({});
  }

  get upcomingAppointments() {
    return this.installation.appointments
    .filter((appointment: any) => appointment.appointment_status_id === 2 || appointment.appointment_status_id === 3
    || appointment.appointment_status_id === 5)
    .sort((a: any, b: any) => {
      const c: any = new Date(a.scheduled_start);
      const d: any = new Date(b.scheduled_start);
      return d - c;
    });
  }

  get upcomingAppointmentsExist() {
    return this.upcomingAppointments.length > 0
  }

  get completedAppointments() {
    return this.installation.appointments
    .filter((appointment: any) => appointment.appointment_status_id === 1 || appointment.appointment_status_id === 4)
    .sort((a: any, b: any) => {
      const c: any = new Date(a.scheduled_start);
      const d: any = new Date(b.scheduled_start);
      return d - c;
    });
  }

  get completedAppointmentsExist() {
    return this.completedAppointments.length > 0
  }

  get currInstallation () {
    return installationsModule.currentInstallation;
  }

  optimalTextColor(color: string) {
    return getOptimalTextColor(color);
  }

  getFormattedAppointmentDuration(appointment: any) {
    if (appointment.appointment_status_id == 4) {
      if (appointment.actual_start && appointment.actual_end) {
        return this.setDuration(appointment.actual_start, appointment.actual_end);
      }
      else {
        return '-';
      }
    }
    else {
      if (appointment.scheduled_start && appointment.scheduled_end) {
        return this.setDuration(appointment.scheduled_start, appointment.scheduled_end);
      }
      else {
        return '-';
      }
    }

  }

  appointmentIsComplete(appointment: any) {
    if(appointment.appointment_status_id == 4) 
      return true;
    else
      return false;
  }

  addAppointment () {
    commonModule.showModal({ name: 'add-appointment-modal', payload: { installation: this.installation}})
  }

  editAppointment (appointment: any){
    commonModule.showModal({ name: 'edit-appointment-modal', payload: { appointment: appointment }})
  }

  deleteAppointment (appointment: any){
    commonModule.showModal({ name: 'delete-appointment-modal', payload: { appointment: appointment }})
  }

  endAppointment (appointment: any){
    commonModule.showModal({ name: 'end-appointment-modal', payload: { appointment: appointment, installation: this.currInstallation }})
  }

  cancelAppointment (appointment: any){
    commonModule.showModal({ name: 'cancel-appointment-modal', payload: { appointment: appointment }})
  }

  formatDate (date: Date): any {
    if (date == undefined || date == null) {
      return '-'
    } else {
      return TimeUtil.formatDateTime(date)
    }
  }

  statusToName(status: string) {
    return appointmentStatusToName(status)
  }

  statusToColor(status: string){
    return appointmentStatusToColor(status)
  }

  setDuration(start: Date, end: Date) {
    return calcDuration(start, end);
  }

  getItemText(id: number) {
    return primaryItemText(id, itemsModule.allCompanyPrimaryItems);
  }

  get jobDescriptionIsEnabled() {
    return (companyModule.company?.preferences as any)?.job_description;
  }

};
