
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { commonModule, appointmentsModule, actionTypesModule, dashboardModule, itemsModule, companyModule } from '@/store/modules/store-accessor'
import { getOptimalTextColor } from "@/helpers/data";
import AssigneeCombo from "@/components/Combobox/AssigneeCombo.vue";
import AssigneeMultiUserCombo from '@/components/Combobox/AssigneeMultiUserCombo.vue';

import _ from "lodash";

@Component({
  components: {
    AssigneeCombo,
    AssigneeMultiUserCombo,
  },
})
export default class QuickAddAppointment extends Vue {
    @Prop() readonly step: number;
    @Prop() readonly currentStep: any;
    @Prop() readonly installation: any;

    date = false;
    time = false;
    addAppointmentLoading = false;
    assigneeLoading = false;
    actionLoading = false;
    isCompleted = false;
    dateMenu = false;
    loadingInstallationPrimaryItems = true;


    appointmentModel = {
        type_id: {text: "", color: "", cost: 0, id: 0},
        assignee_id : "",
        assignee_ids: [] as any[],
        appointment_is_confirmed: true,
        appointment_status_id: 2,
        scheduled_start: null,
        scheduled_start_time: '',
        duration: this.durationCombo[1] || '',
        appointment_notes: "",
        installation_id: -1,
        task_id: undefined,
        installation_item_id: this.defaultInstallationItem(),
        description: '',
    }

    scheduledDateRule = [
        (val: any) => !!val || this.$i18n.t("scheduled_day_required"),
    ]

    userRule= [
        (val: any) => !!val || this.$i18n.t("user_required"),
    ]
    
    scheduledTimeRule = [
        (val: any) => !!val || this.$i18n.t("scheduled_time_required"),
    ]

    durationRule = [
        (val: any) => !!val || this.$i18n.t("duration_required"),
    ]

    typeRule= [
        (val: any) => !!val && val.text != "" || this.$i18n.t("action_required"),
    ]

    installationRule = [
        (val: any) => !!val || this.$i18n.t("installation_required"),
    ]
    
    notesRule= [
        (val: any) => {
            if(val != null)
                return val.length <= 1000 || this.$i18n.t("notesLengthRule");
            else 
                return true;
        },
    ]

    titleRule = [
      (val: any) => !!val || this.$i18n.t("title_required"),
      (val: any) => val.length <= 300 || this.$i18n.t("form_title_length_rule"),
    ]

    jobDescriptionRule = [
       (val: any) => val.length <= 80 || this.$i18n.t("jobDescriptionLengthRule"),
    ]

    get durationCombo () {
      return commonModule.durationCombo;
    }

    get actionsCombo() {
        return actionTypesModule.actionTypesCombo;
    }

    get installationsCombo () {
        return commonModule.installationsCombo;
    }

    get isAssigneeLoading () {
        return this.assigneeLoading
    }

    get isActionsLoading () {
        return this.actionLoading
    }

    get installtionPrimaryItems() {
        return itemsModule.installationPrimaryItems;
    }
    
    get isLoadingInstallationPrimaryItems() {
        return this.loadingInstallationPrimaryItems;
    }

    get disablePrimaryItems() {
        return this.loadingInstallationPrimaryItems || this.installtionPrimaryItems.length === 0;
    }

    optimalTextColor(color: string) {
        return getOptimalTextColor(color);
    }

    @Watch('currentStep')
    async stepUpdate () {
        if(this.currentStep == this.step) {
            if (this.installation.id != -1) {
                this.assigneeLoading = true;
                await commonModule.getPersonnelCombo();
                this.assigneeLoading = false;

                await itemsModule.getInstallationPrimaryInventoryItems(this.installation.id);
                this.loadingInstallationPrimaryItems = false;

                this.actionLoading = true;
                await commonModule.getActionCombo();
                await commonModule.getDurationCombo();
                await actionTypesModule.getActionTypesCombo();
                this.actionLoading = false;
                this.appointmentModel.installation_id = this.installation.id;
                (this.appointmentModel.installation_item_id as any) = this.defaultInstallationItem();
            }
        }
    }

    updateAppointmentAssignee(assignees: any) {
        if(assignees.length > 0) {
            this.appointmentModel.assignee_id = assignees[0];
        }
        this.appointmentModel.assignee_ids = assignees;            
    }

    async endStep () {
        if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
            this.addAppointmentLoading = true;

           let appointmentModelCopy = _.cloneDeep(this.appointmentModel);

           if (typeof this.appointmentModel.assignee_id === 'undefined' || this.appointmentModel.assignee_id == "" || this.appointmentModel.assignee_id == null) {
             appointmentModelCopy.assignee_id = "";
            }

            appointmentModelCopy.installation_id = this.installation.id
            await appointmentsModule.createAppointment(appointmentModelCopy);

            switch(this.$route.name) {
                case 'Dashboard':
                    dashboardModule.getDashboardAppointmentsAction(dashboardModule.filters);
                    dashboardModule.getDashboardAppointmentsBarsAction(dashboardModule.filters);
                    break;
                case 'Appointments':
                    appointmentsModule.getAppointmentsAction(appointmentsModule.filters);
                    break;
                case 'Calendar':
                    appointmentsModule.getCalendarAppointmentsAction(appointmentsModule.filters);
                    break;
            }

            this.addAppointmentLoading = false;
            this.$emit('close-modal');
        }
    }

    defaultInstallationItem() {
        if(this.installtionPrimaryItems && this.installtionPrimaryItems.length === 1) {
            return  { primary_items: this.installtionPrimaryItems[0].primary_items, id: this.installtionPrimaryItems[0].id};
        }
        return { primary_items: '', id: 0};
    }

    prevStep() {
        this.$emit('prev-step')
    }

    get jobDescriptionIsEnabled() {
        return (companyModule.company?.preferences as any)?.job_description;
    }

    clearTime () {
        this.appointmentModel.scheduled_start_time = ''
    }

}
