
import { Component, Vue, Prop } from 'vue-property-decorator'
import { commonModule, appointmentsModule, actionTypesModule, installationsModule, itemsModule, itemTypesModule, companyModule  } from '@/store/modules/store-accessor'
import { isDecimalNumber, getOptimalTextColor, isNumber } from "@/helpers/data";
import AssigneeUserCombo from '@/components/Combobox/AssigneeUserCombo.vue';
import _ from 'lodash';
import dayjs from 'dayjs';

@Component({
    components: {
      AssigneeUserCombo,
    },
  })
export default class EndAppointmentModal extends Vue {
  @Prop()
  payload!: any;

  isEndingAppointment = false;
  addMaterials = false;
  payloadAssignee = {} as any;
  addExpenses = false;
  installationItem = '';

  jobModel = {
   type_id: this.findActionType(this.payload.appointment) as any,
   materials: [{
        id: Math.random() * -1, //Need Random and non-integer and also negative, for this to work with the backend properly
        cost: 0, //This needs to be initialized not null for the reduce to work
        description: '',
        notes: ''
    }] as any,
   assignee_id: this.getAssigneeId(this.payload.appointment.assignee_id),
   installation_id: this.payload.installation.info ? this.payload.installation.info.id : this.payload.appointment.installation_id,
   is_complete: false,
   notes: "",
   payment_amount: null,
   is_paid: false,
   radioValue: "user",
   id: this.payload.appointment.id,
   job_id: this.payload.appointment.job_id,
   payment_type: this.paymentTypesCombo[1],
   installation_item_id: this.payload.appointment.installation_item_id,
   expenses: [{
        id: Math.random() * -1, //Need Random and non-integer and also negative, for this to work with the backend properly
        cost: 0,
        notes: '',
    }] as any,
   description: this.payload.appointment.description || '',
  }

  photoModel = {
    add_photo: false,
    photo: null,
    url: null,
    notes:  '',
  }

  typeRule= [
    (val: any) => (val || '').length > 0 || this.$i18n.t("actions_required"),
  ]
  userRule= [
    (val: any) => !!val || this.$i18n.t("user_required"),
  ]
  notesRule= [
    (val: any) => {
      if(val != null) return val.length <= 1000 || this.$i18n.t("form_notes_length_rule");
      else return true;
    },
  ]
  expenseNotesRule = [
    (val: any) => {
      if(val != null) return val.length <= 30 || this.$i18n.t("expenseNotesLengthRule");
    }
  ]
  materialNotesRule = [
    (val: any) => {
      if(val != null) return val.length <= 1000 || this.$i18n.t("notesLengthRule");
      else return true;
    },
  ]
  actionNotesRule = [
    (val: any) => {
      if(val != null) return val.length <= 1000 || this.$i18n.t("notesLengthRule");
      else return true;
    },
  ]
  paymentTypeRule= [
    (val: any) => (val || '').id > 0 || this.$i18n.t("payment_type_required"),
  ]
  photoRules = [
    (val: any) => !!val || this.$t('photoRequired'),
  ]
  photoNotesRule = [
    (val: any) => val.length <= 50 || this.$i18n.t("notesForPhotoRule"),
  ]
  costRule:any =[
    (val: any) => val !== '' && val >= 0 || this.$i18n.t("cost_required")
  ]
  descriptionRule= [
      (val: any) => (val == null || ((val?.text?.length <= 80) || val?.length <= 80 )) || this.$i18n.t("client_form_description_length_rule"),
  ]
  paymentRule:any =[]
  jobDescriptionRule = [
    (val: any) => val.length <= 80 || this.$i18n.t('installationDescriptionLengthRule'),
  ]


  nextEventRule = [
    (val: any) => !!val || this.$i18n.t("nextEventRuleText"),
  ]

  reminderRule = [
      (val: any) => val !== '' && val >= 0 || this.$i18n.t("reminderRuleText"),
  ]

  reminderOptionRule = [
      (val: any) => ((!!val || val == '') && val.text.length > 0) || this.$i18n.t("reminderOptionRuleText"),
  ]

  get periodicCombo () {
      return commonModule.periodicCombo;
  }

  get jobTotalCost() {    
    let actionTotal =  this.jobModel.type_id?.reduce((accumulator: number, item: any) => {return accumulator + (!isNaN(parseFloat(item.cost)) ? parseFloat(item.cost) : 0)}, 0) 
    let materialTotal = this.jobModel.materials?.reduce((accumulator: number, item: any) => {return accumulator + (!isNaN(parseFloat(item.cost)) ? parseFloat(item.cost) : 0)}, 0);

    let total = actionTotal;

    if(this.addMaterials)
      total += materialTotal

    this.paymentRule = [
      (val: any) => {
        if (val === '' || isNaN(val)) {
          return this.$i18n.t("requiredNonZeroPaymentRule");
        }
        return val > 0 || this.$i18n.t("nonZeroPaymentRule");
      },
      (val: any) => val <= total || this.$i18n.t("actualCostRule")
    ]

    return total
  }

  actualCostRule:any =[]

  get actionsCombo () {
      return actionTypesModule.actionTypesCombo;
  }

  get assigneeIdCombo () {
      return commonModule.personnelCombo;
  }

  get paymentTypesCombo () {
    for (let i = 0; i < commonModule.paymentTypeCombo.length; i++) {
      (commonModule.paymentTypeCombo[i]["payment_type"] as any) = this.$t(commonModule.paymentTypeCombo[i]["payment_type"]);
    }
    return commonModule.paymentTypeCombo;
  }

  get itemTypesCombo () {
    return itemTypesModule.itemTypesCombo;
  }

  onlyNumbers(evt: any) {
      isNumber(evt);
  }

  async hideModal () {
    commonModule.hideModal();
  }

  findActionType(appointment: any) {
    let combo: any = actionTypesModule.actionTypesCombo;
    const foundType = combo.find((item: any) => item.id === appointment.action_type_id);
    if(foundType) {
      return [{ text: foundType.text, color: foundType.color, cost: foundType.cost, id: foundType.id, is_periodic: foundType.is_periodic, 
                next_task: foundType.next_task, reminder_option_value: foundType.reminder_option_value, 
                reminder_option_combo: foundType.reminder_option_combo }] as any;
    } else {
      return [] as any;
    }
  }

  getAssigneeId(name: string) {
    const combo: any = commonModule.personnelCombo;
    const foundItem = combo.find((item: any) => item.name === name);
    if(foundItem) {
      return { name: foundItem.name, id: foundItem.id };
    } else {
      return ''
    }
  }
  optimalTextColor(color: string) {
    return getOptimalTextColor(color);
  }

  async mounted() {
    this.installationItem = this.getItemText(this.payload.appointment.installation_item_id);
    this.payloadAssignee = this.getAssigneeId(this.payload.appointment.assignee_id);
    commonModule.initSnackbar({});
    await commonModule.getPersonnelCombo();
    await actionTypesModule.getActionTypesCombo();
    await commonModule.getPaymentTypeCombo();
    this.jobModel.payment_type = this.paymentTypesCombo[1];
    await itemTypesModule.getItemTypesCombo();
    await itemsModule.getAllInventoryItems('');
    await commonModule.getPeriodicCombo(1);
  }

  updateAssignee(assignee: any) {
      if(assignee) {
        this.jobModel.assignee_id = assignee;
      }
    }

  addPhoto() {
    commonModule.showModal({ name: 'add-photo-to-installation-modal', payload: { installation: this.jobModel, tab: 'closeAppointment' }})
  }

  PreviewPhoto() {
    if(this.photoModel.photo !== undefined && this.photoModel.photo !== null) {
      (this.photoModel.url as any) = URL.createObjectURL(this.photoModel.photo);
    } else {
      // Clear photo preview
      (this.photoModel.add_photo as any) = null;
      (this.photoModel.url as any) = '';
      this.photoModel.add_photo = true; // Keep the checkbox checked
    }
  }

  repayment() {
    this.jobModel.payment_amount = this.jobTotalCost;
  }

  addMaterial() {
    let newMaterial = {
        id: Math.random() * -1,
        cost: 0, //This needs to be initialized not null for the reduce to work
        description: '',
        notes: ''
    } as any

    this.jobModel.materials.push(newMaterial);
  }

  deleteMaterial(index: number) {
    if (this.jobModel.materials.length > 1) {
      this.jobModel.materials.splice(index, 1);
    }
  }

  addExpense() {
    let newExpense = {
        id: Math.random() * -1,
        cost: 0,
        notes: ''
    } as any

    this.jobModel.expenses.push(newExpense);
  }

  deleteExpense(index: number) {
    if (this.jobModel.expenses.length > 1) {
      this.jobModel.expenses.splice(index, 1);
    }
  }

  doEndAppointment () {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        this.isEndingAppointment = true;
        let job = _.cloneDeep(this.jobModel);

        if (this.photoModel.add_photo) {
          let formData = new FormData();
          formData.append("image", (this.photoModel.photo as any));
          formData.append("notes", (this.photoModel.notes as any));

          formData.append("job_id", job.job_id);
          installationsModule.addInstallationPhoto({installationId: job.installation_id, photo: formData});
        }

        if(this.addMaterials) {
          job.materials = job.materials.map((material: any) => {
            if (typeof material.description === 'object' && material.description != null) {
              material.description = material.description.text
              return material
            }
            else {
              return material
            }
          })
        }
        else {
          delete job.materials
        }
        
        job.type_id.map((item: any) => {
          item.due_at =  item.is_periodic ? dayjs(item.next_task).startOf('day').subtract((parseInt(item.reminder_option_value) * item.reminder_option_combo.period), 'days').format('YYYY-MM-DD') : null
        })

        if(!this.addExpenses) {
          delete job.expenses;
        }

        appointmentsModule.getCalendarAppointmentsAction(appointmentsModule.filters);
        appointmentsModule.endAppointment(job);
        installationsModule.getInstallationInfo(job.installation_id);
        this.hideModal()
    }  
  }
  onlyDecimalNumbers(evt: any) {
    isDecimalNumber(evt);
  }

  get jobDescriptionIsEnabled() {
    return (companyModule.company?.preferences as any)?.job_description;
  }

  getItemText(ids: number) {
    const idArray = Array.isArray(ids) ? ids : [ids];
    const arr: any = itemsModule.allCompanyPrimaryItems

    for (let i = 0; i < arr.length; i++) {
      if ((Array.isArray(arr[i].id) && arr[i].id.some((item: any) => idArray.includes(item))) || (!Array.isArray(arr[i].id) && idArray.includes(arr[i].id))) {
       (itemsModule.installationPrimaryItems as any).push({ primary_items: arr[i].primary_items, id: ids })
        return arr[i].primary_items;
      }
    }
    return '-';
  }

};
