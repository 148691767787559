
import { commonModule, appointmentsModule, filterModule } from "@/store/modules/store-accessor";
import Vue from "vue";
import Component from "vue-class-component";
import FilterList from "./FilterList.vue";
import { sortDateRange } from "@/helpers/data";

@Component({
  components: {
    FilterList,
  },
})
export default class CalendarFilter extends Vue {

  get personnelUserNameFilter() {
    return commonModule.personnelCombo?.map((obj) => ({...obj, icon: obj?.is_partner ? 'mdi-account-multiple' : 'mdi-account'}));
  }

  get calendarFilters() {
    return filterModule.calendarFilters;
  }

  selectedUsers: string[];
  selectedActions: string[];

  updateUsers(data: string[]) {
    this.selectedUsers = data;
    this.submit();
  }

  updateActions(data: string[]) {
    this.selectedActions = data;
    this.submit();
  }

   async submit() {
    appointmentsModule.setIsLoadingIndicator(true);
    let queryObj: any = {};
    
    let userIds = [];
    let tempId;

     if (this.selectedUsers != undefined) {
       for (let i = 0; i < this.selectedUsers.length; i++) {
         tempId = (this.selectedUsers[i] as any).id
         userIds.push(tempId);
       }
     }

    if (userIds != undefined && userIds.length > 0) {
      queryObj["assignee_id[]"] = userIds;
    }

    if (appointmentsModule.queryObject.date_from != null && appointmentsModule.queryObject.date_to != null) {
      queryObj["date_from"] = appointmentsModule.queryObject.date_from.toString();
      queryObj["date_to"] = appointmentsModule.queryObject.date_to.toString();
    }

    if (this.selectedActions != undefined && this.selectedActions.length > 0) {
      queryObj["action_types[]"] = this.selectedActions;
    }

    this.$router.replace({ query: queryObj }).catch(err => {/* */ });

    // Check if user selected any of the queries
    const queryIndex = this.$route.fullPath.indexOf('?');
    if (queryIndex >= 0) {
      const query = this.$route.fullPath.substring(queryIndex);
      appointmentsModule.setFilters(query);
    } else {
      appointmentsModule.setFilters("");
      delete appointmentsModule.queryObject.actionTypes
    }

    appointmentsModule.setQueryObject(queryObj);
    await appointmentsModule.getCalendarAppointmentsAction(appointmentsModule.filters);
    appointmentsModule.setIsLoadingIndicator(false);
  }
}
