
import Vue from "vue";
import { Component, Prop, Emit, Watch } from "vue-property-decorator";

@Component
export default class FilterList extends Vue {
  @Prop() options!: any[];
  @Prop() label!: string;
  @Prop() text!: string;
  @Prop() multiple!: boolean;
  @Prop() icon!: boolean;
  @Prop({ default: true }) clearable!: boolean;
  @Prop({ default: true }) deletableChips!: boolean;
  @Prop({ default: false }) dense!: boolean;
  @Prop({ default: true }) chips!: boolean;
  @Prop({ default: true }) smallChips!: boolean;
  @Prop({ default: true }) rounded!: boolean;
  @Prop({ default: true }) filled!: boolean;
  @Prop({ default: false }) preSelectFirstItem!: boolean;
  @Prop({ default: false }) selectAll!: boolean;
  @Prop({ default: false }) groupSelected!: boolean;
  @Prop({ default: null }) noDataMessage!: string;
  
  selectedFilters = [] as any;
  allOptionsSelectedFlag = false;

  mounted() {
    if(this.preSelectFirstItem) {
      this.selectedFilters = this.options[0];
    }
  }

  doSelectAll() {
    this.allOptionsSelectedFlag = !this.allOptionsSelectedFlag;
    if (this.allOptionsSelectedFlag) {
      this.selectedFilters = this.options;
    }
    else {
      this.selectedFilters = [];
    }

  }

  @Watch("selectedFilters")
  selectFilter() {
    this.$emit('update-filter', this.selectedFilters);
  }

  toggleSelectedItem(itemId: string) {
    const index = this.selectedFilters.findIndex((item: any) => item.id === itemId);
    if (index !== -1) {
      this.selectedFilters.splice(index, 1);
    } else {
      this.selectedFilters.push({ id: itemId });
    }
  }

  isSelected(itemId: string) {
    return this.selectedFilters.map((item: any) => item.id).indexOf(itemId) !== -1;
  }

}
