
import { Component, Vue } from 'vue-property-decorator'
import { commonModule, jobsModule, installationsModule, filterModule, companyModule, itemsModule } from '@/store/modules/store-accessor'
import JobFilter from '../components/Filter/JobFilter.vue'
import { formatAmount, sortTableDates, primaryItemText, pagesExportLimit, templatesFilenameLanguage } from "@/helpers/data";
import dayjs from 'dayjs';
import FileSaver from 'file-saver';

@Component({
  components: { JobFilter }
})
export default class Jobs extends Vue {
  tableheaders: any [] = [
    { text: this.$t("tag"), sortable: true, class: 'row-style', value: 'tag_id' },
    { text: this.$t("job"), sortable: true, class: 'row-style', value: 'actions' },
    { text: this.$t("address"), sortable: true, class: 'row-style', value: 'address' },
    { text: this.$t("area"), sortable: true, class: 'row-style', value: 'area' },
    { text: this.$t("client"), sortable: true, class: 'row-style', value: 'client_name' },
    { text: this.assigneeLabel, sortable: true, class: 'row-style', value: 'assignee_id' },
    { text: this.$t("last_job_entry"), sortable: true, class: 'row-style', value: 'readable_last_job_entry_at', sort: (a: string, b: string) => sortTableDates(a, b) },
    { text: this.$t("item"), sortable: true, class: 'row-style', value: 'job_installation_item_id' },
    { text: this.$t("cost"), sortable: true, class: 'row-style', value: 'cost' },
    { text: this.$t("balance"), align: 'left', sortable: true, class: 'row-style', value: 'balance' },
    { text: '', align: 'left', class: 'row-style', sortable: true, value: 'is_complete', width: '75px' }
  ]

  model: any = {
    search: '',
    selected: [] as string[]
  }

  timer: number;
  query: any;

  onSearchChange(val: string) {
    clearTimeout(this.timer)

    this.timer = setTimeout(async () => {
      jobsModule.setIsLoadingTable(true);
      if (val && val.length > 0) {
        jobsModule.setSearch(val);
        jobsModule.queryObject.search = jobsModule.search;
      } else {
        jobsModule.setSearch('');
        delete jobsModule.queryObject.search;
      }
      this.$router.replace({ query: jobsModule.queryObject });
      
      // Check if user selected any of the queries
      const queryIndex = this.$route.fullPath.indexOf('?');
      if(queryIndex >= 0) {
        const query = this.$route.fullPath.substring(queryIndex);
        jobsModule.setFilters(query);
      } else {
        jobsModule.setFilters("");
      }

      await jobsModule.getJobs(jobsModule.filters);
      await jobsModule.getJobTotals(jobsModule.filters);
      jobsModule.setIsLoadingTable(false);
    }, 500);
  }

  get jobsLength() {
    return jobsModule.jobs.length
  }

  get searchLabel() {
    return this.$t("jobsSearch")
  }

  get jobs(){
    return jobsModule.jobs
  }

  get jobTotals() {
    return jobsModule.jobTotals
  }

  jobTypes( job: any) {
    return job.actions
  }

  formatBalance(balance: number) {
    return formatAmount(balance);
  }

  isDownloadingJobsPdf = false;

  async mounted () {
    /*
     TODO: Handle installations properly when UX is clear.
     */
    await companyModule.getCompanyInfo();

    // Default filter for the current day
    const dateFrom = dayjs().startOf('day').toISOString();
    const dateTo = dayjs().endOf('day').toISOString();

    jobsModule.getJobs(`?date_from=${dateFrom}&date_to=${dateTo}`);
    jobsModule.getJobTotals(`?date_from=${dateFrom}&date_to=${dateTo}`);
    jobsModule.queryObject.date_from = dateFrom;
    jobsModule.queryObject.date_to = dateTo;
    jobsModule.setFilters(`?date_from=${dateFrom}&date_to=${dateTo}`);
    jobsModule.setSearch(''); // clear search filter when revisiting the page
    commonModule.getActionCombo();
    commonModule.getPersonnelCombo();
    commonModule.getPaymentTypeCombo();
    itemsModule.getAllCompanyPrimaryInventoryItems();
    commonModule.initSnackbar({});

    if((companyModule.company?.preferences as any)?.job_description) {
      this.tableheaders.splice(1, 0, { text: this.$t('description'), sortable: true, class: 'row-style', value: 'description' });
    }
  }

  destroyed() {
     jobsModule.clearJobInfo();
  }

  get isLoadingTable() {
    return jobsModule.isLoadingTable;
  }

  get assigneeLabel() {
    if(companyModule.company?.modules?.includes('DFB_PORTAL_PARTNERS')) {
      return this.$i18n.t('userPartner');
    }
    else {
      return this.$i18n.t('user');
    }
  }

  async openInstallationSideView (job: any) {
    try {
      jobsModule.setIsLoadingTable(true);
      await installationsModule.getInstallationInfo(job.installation_id);
      jobsModule.setIsLoadingTable(false);
      commonModule.showSideview({ name: 'installations-sideview', payload: { installationId: job.installation_id, tab: 1, jobId: job.job_id } });
    } catch (error) {
      jobsModule.setIsLoadingTable(false);
    }
  }

  async refreshData() {
    await jobsModule.getJobs(jobsModule.filters);
    await jobsModule.getJobTotals(jobsModule.filters);
    await filterModule.getJobFilters();
  }

  getItemText(id: number) {
    return primaryItemText(id, itemsModule.allCompanyPrimaryItems);
  }

  downloadPdfLengthCheck() {
    return this.jobsLength > 0 && this.jobsLength <= pagesExportLimit();
  }

  async donwloadJobsPdf() {
    this.isDownloadingJobsPdf = true;
    const utcOffset = dayjs().utcOffset();

    const data = { 'utcOffset': utcOffset, 'language': localStorage.getItem('lang') };
    
    const company = await companyModule.getCompanyInfo();
    const filename = `${templatesFilenameLanguage()}_${company.entity_name}_DfB_jobs_${dayjs().format('YYYY_MM_DD_HH_mm_ss')}.pdf`.replace(/ /g, '_');
    const pdf = await  jobsModule.getJobsPdfExportAction({ query: jobsModule.filters, data: data });
    const file = new File([pdf], filename);
    FileSaver.saveAs(file);

    this.isDownloadingJobsPdf = false;
  }

}
