
import { Component, Vue, Prop } from 'vue-property-decorator'
import { commonModule, installationsModule, companyModule, tasksModule, appointmentsModule, statsModule, jobsModule, clientsModule, dashboardModule, filterModule, itemsModule } from '@/store/modules/store-accessor'
import LoadingIndicator from '@/components/TheLoadingIndicator.vue'
import InstallationsSideviewGeneralTab from '@/components/sideviews/installations/InstallationsSideviewGeneralTab.vue'
import InstallationsSideviewJobsTab from '@/components/sideviews/installations/InstallationsSideviewJobsTab.vue'
import InstallationsSideviewAppointmentsTab from '@/components/sideviews/installations/InstallationsSideviewAppointmentsTab.vue'
import InstallationsSideviewItemsTab from '@/components/sideviews/installations/InstallationsSideviewItemsTab.vue'
import InstallationsSideviewPhotosTab from '@/components/sideviews/installations/InstallationsSideviewPhotosTab.vue'
import InstallationsSideviewTasksTab from '@/components/sideviews/installations/InstallationsSideviewTasksTab.vue'
import InstallationsSideviewFilesTab from '@/components/sideviews/installations/InstallationsSideviewFilesTab.vue'
import { TimeUtil } from '@/helpers/TimeUtil'
import FileSaver from 'file-saver';
import dayjs from 'dayjs';
import TheMiniLoader from '@/components/TheMiniLoader.vue'
import TaskModule from '@/store/modules/tasks-module'
import { formatAmount, templatesFilenameLanguage } from "@/helpers/data";

@Component({
  components: {
    LoadingIndicator,
    InstallationsSideviewGeneralTab,
    InstallationsSideviewJobsTab,
    InstallationsSideviewAppointmentsTab,
    InstallationsSideviewItemsTab,
    InstallationsSideviewPhotosTab,
    InstallationsSideviewTasksTab,
    InstallationsSideviewFilesTab,
    TheMiniLoader
  }
})
export default class InstallationsSideview extends Vue {
  @Prop()
  loading!: boolean;

  @Prop()
  payload!: any;
  
  currTab = this.payload['tab'] | 0;
  jobId = this.payload['jobId'] | 0;
  appointmentId = this.payload['appointmentId'] | 0;
  taskId = this.payload['taskId'] | 0;

  isEditing!: boolean;

  async hideSideview () {
    commonModule.hideSideview();
  }

  get currInstallationId () {
    return this.payload['installationId'];
  }

  get currInstallation () {
    return installationsModule.currentInstallation;
  }

  get exportPDFEnabled () {
    //TODO Ths is quick and dirty, we need to change this and implement it thourgh feature flags
    return companyModule.company.key == 'GSOP' ? true : false
  }

  async installationPDF() {
      const doc = await installationsModule.getInstallationPDF(this.currInstallationId);
      var file = new File([doc], `${templatesFilenameLanguage()}_${this.currInstallation.info.tag_id}_${dayjs().format('DD_MM_YYYY')}.pdf`);
      FileSaver.saveAs(file);
  }

  formatDate (date: Date): string {
    return TimeUtil.formatDateTime(date)
  }

  formatBalance(balance: number) {
    return balance ? formatAmount(balance) : '0';
  }

  updated() {
    this.isEditing = false;
  }

  get isLoading() {
    return installationsModule.isLoadingSideView;
  }

  get isPremium () {
    return installationsModule.currentInstallation.info.is_premium;
  }

  get tagId () {
    return (installationsModule.currentInstallation.info.tag_id).split("_").pop();
  }

  refreshData () {
    installationsModule.setIsLoadingSideView(true);
    installationsModule.getInstallationInfo(this.currInstallationId);
  }

  async editInstallation() {
    await installationsModule.getInstallationInfo(this.currInstallationId);
    commonModule.showModal({ name: 'edit-installation-modal', payload: { view: "installationsSideview"}})
  }

  deleteInstallation() {
    commonModule.showModal({ name: 'delete-installation-modal', payload: { installation: this.currInstallation.info}})
  }

  close () {
    installationsModule.unsetCurrentInstallation();

    switch(this.$route.name) {
      case 'Dashboard':
        dashboardModule.getDashboardAppointmentsAction(dashboardModule.filters);
        dashboardModule.getDashboardAppointmentsBarsAction(dashboardModule.filters);
        filterModule.getDashboardTasksFilters();
        dashboardModule.getDashboardPendingTasks(dashboardModule.taskFilters);
        itemsModule.getAllCompanyPrimaryInventoryItems();
        break;
      case 'Installations':
        installationsModule.getInstallationsAction(installationsModule.filters);
        break;
      case 'Jobs':
        jobsModule.getJobs(jobsModule.filters);
        jobsModule.getJobTotals(jobsModule.filters);
        break;
      case 'Map':
        installationsModule.getInstallationsAction(installationsModule.filters);
        break;
      case 'Calendar':
        appointmentsModule.getCalendarAppointmentsAction(appointmentsModule.filters);
        break;
      case 'Appointments':
        filterModule.getAppointmentsFilters();
        appointmentsModule.getAppointmentsAction(appointmentsModule.appointmentsFilters);
        break;
      case 'Tasks':
        tasksModule.getTasks(tasksModule.filters);
        tasksModule.getPendingTasks();
        break;
    }
    this.hideSideview()
  }

};
