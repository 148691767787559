
import { Component, Vue, Prop } from 'vue-property-decorator'
import { commonModule, jobsModule, actionTypesModule, itemsModule, companyModule } from '@/store/modules/store-accessor'
import AssigneeUserCombo from '@/components/Combobox/AssigneeUserCombo.vue';
import AssigneeMultiUserCombo from '@/components/Combobox/AssigneeMultiUserCombo.vue';
import { getOptimalTextColor, primaryItemText } from "@/helpers/data";

@Component({
  components: {
    AssigneeUserCombo,
    AssigneeMultiUserCombo
  },
})
export default class AddAppointmentToJobModal extends Vue {
  @Prop()
  payload!: any;

  isAddingAppointment = false;

  menu3= false;
  menu4= false;

  jobModel = {
   type_id: {text: "", color: "", cost: 0, id: 0},
   assignee_id: "",
   assignee_ids: [] as any[],
   appointment_status_id: 2,
   scheduled_start: "",
   scheduled_start_time: "",
   duration: this.durationCombo[1] || '',
   appointment_notes: "",
   installation_id: this.payload.job.installation_id,
   id: this.payload.job.id,
   installation_item_id: this.getItemText(this.payload.job.installation_item_id),
   description: this.payload.job.description || '',
  }

  updateAssignee(assignees: any[]) {
    if(assignees.length > 0) {
      this.jobModel.assignee_id = assignees[0];
    }
    this.jobModel.assignee_ids = assignees;
  }

  scheduledDateRule = [
    (val: any) => !!val || this.$i18n.t("scheduled_day_required"),
  ]

  userRule= [
    (val: any) => !!val || this.$i18n.t("user_required"),
  ]

  typeRule= [
    (val: any) => (val || '').text.length > 0 || this.$i18n.t("action_required"),
  ]
  scheduledTimeRule= [
    (val: any) => !!val || this.$i18n.t("scheduled_time_required"),
  ]
  durationRule= [
    (val: any) => !!val || this.$i18n.t("duration_required"),
  ]
  notesRule= [
    (val: any) => {
      if(val != null) return val.length <= 1000 || this.$i18n.t("form_notes_length_rule");
      else return true;
    },
  ]
  descriptionRule = [
    (val: any) => val.length <= 80 || this.$i18n.t("jobDescriptionLengthRule"),
  ]


  get durationCombo () {
      return commonModule.durationCombo;
  }

  get actionsCombo () {
      return actionTypesModule.actionTypesCombo;
  }

  get assigneeIdCombo () {
      return commonModule.personnelCombo;
  }

  get jobDescriptionIsEnabled() {
    return (companyModule.company?.preferences as any)?.job_description;
  }
  
  optimalTextColor(color: string) {
    return getOptimalTextColor(color);
  }

  getItemText(id: number) {
    return primaryItemText(id, itemsModule.installationPrimaryItems);
  }

  async hideModal () {
    commonModule.hideModal();
  }

  async mounted() {
    commonModule.initSnackbar({});
    await commonModule.getPersonnelCombo();
    await commonModule.getActionCombo();
    await commonModule.getDurationCombo();
    await actionTypesModule.getActionTypesCombo();
  }

  doAddAppointment () {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      this.isAddingAppointment = true;
       // Check if user removed the selected assignee_id
       if (typeof this.jobModel.assignee_id === 'undefined' || this.jobModel.assignee_id == "" || this.jobModel.assignee_id == null) {
        this.jobModel.assignee_id = '';
      }
      jobsModule.createAppointmentToJob(this.jobModel);
      this.hideModal()
    }
  }
};
