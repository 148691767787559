
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { commonModule, jobsModule, actionTypesModule, partnersModule } from '@/store/modules/store-accessor'
import _ from 'lodash';
import { isDecimalNumber, getOptimalTextColor, isNumber } from '@/helpers/data';
import dayjs from 'dayjs';
import AssigneeCombo from '@/components/Combobox/AssigneeCombo.vue';

@Component({
  components: {
    AssigneeCombo,
  },
})
export default class AddActionsToJobModal extends Vue {
  @Prop()
  payload!: any;

  createdAtMenu = false;
  createAtTimeMenu = false;
  isAddingAction = false;
  
  jobActionsModel = {
   type_id: [] as any[],
   assignee_id: this.getAssigneeId(this.payload.job.partner_uuid ? this.payload.job.partner_uuid : commonModule.user.uuid),
   installation_id: this.payload.job.installation_id,
   id: this.payload.job.id,
   cost_disabled: this.payload.job.cost_disabled,
   created_at_date: dayjs().format('YYYY-MM-DD'),
   created_at_time: dayjs().format('HH:mm'),
   installation_item_id: this.payload.job.installation_item_id,
  }

  typeRule= [
    (val: any) => (val || '').length > 0 || this.$i18n.t("actions_required"),
  ]
  userRule= [
    (val: any) => !!val || this.$i18n.t("user_required"),
  ]
  notesRule= [
    (val: any) => {
      if(val != null) return val.length <= 1000 || this.$i18n.t("notesLengthRule");
      else return true;
    },
  ]
  costRule:any =[
    (val: any) => val !== '' && val >= 0 || this.$i18n.t("cost_required")
  ]

  nextEventRule = [
    (val: any) => !!val || this.$i18n.t("nextEventRuleText"),
  ]

  reminderRule = [
      (val: any) => val !== '' && val >= 0 || this.$i18n.t("reminderRuleText"),
  ]

  reminderOptionRule = [
      (val: any) => ((!!val || val == '') && val.text.length > 0) || this.$i18n.t("reminderOptionRuleText"),
  ]

  get periodicCombo () {
      return commonModule.periodicCombo;
  }

  get actionsCombo () {
      return actionTypesModule.actionTypesCombo;
  }

  get assigneeIdCombo () {
      return commonModule.personnelCombo;
  }

  async hideModal () {
    commonModule.hideModal();
  }

  onlyNumbers(evt: any) {
      isNumber(evt);
  }

  async mounted() {
    commonModule.initSnackbar({});
    await commonModule.getPersonnelCombo();
    await commonModule.getPeriodicCombo(1);
    await actionTypesModule.getActionTypesCombo();
  }

  updateAssignee(assignee: any) {
    if(assignee) {
      this.jobActionsModel.assignee_id = assignee;
    }
  }

  get jobTotalCost() {    
    let total =  this.jobActionsModel.type_id.reduce((accumulator: number, item: any) => {return accumulator + parseFloat(item.cost)}, 0);

    return total
  }

 optimalTextColor(color: string) {
    return getOptimalTextColor(color);
  }

  onDateChange(ev: any) {
    this.jobActionsModel.type_id.map(item => {
          item.next_task =  item.is_periodic ? dayjs(ev).startOf('day').add(item.periodic_days, 'days').format('YYYY-MM-DD') : null
        })
  }

  onActionTypeChange(ev: any) {
    this.jobActionsModel.type_id.map(item => {
          item.next_task =  item.is_periodic ? dayjs(this.jobActionsModel.created_at_date).startOf('day').add(item.periodic_days, 'days').format('YYYY-MM-DD') : null
        })
  }

  async doAddJobActions () {
    this.isAddingAction = true;
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        let actionsObj = _.cloneDeep(this.jobActionsModel);

        actionsObj.type_id.map((item: any) => {
                  item.due_at =  item.is_periodic ? dayjs(item.next_task).startOf('day').subtract((parseInt(item.reminder_option_value) * item.reminder_option_combo.period), 'days').format('YYYY-MM-DD') : null
        })
        await jobsModule.createActionsToJob(actionsObj);
        this.hideModal()
    }  
    this.isAddingAction = false;
  }

  onlyDecimalNumbers(evt: any) {
      isDecimalNumber(evt);
    }
    
  getAssigneeId(uuid: string) {
    const combo: any = commonModule.personnelPartnersCombo;
    const foundItem = combo.find((item: any) => item.id === uuid);
    if(foundItem) {
      return { name: foundItem.name, id: foundItem.id };
    } else {
      return ''
    }
  }

};
