
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { commonModule, jobsModule, itemsModule, companyModule } from '@/store/modules/store-accessor'
import { TimeUtil } from '@/helpers/TimeUtil';
import { isDecimalNumber, primaryItemText } from "@/helpers/data";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

@Component
export default class EditJobModal extends Vue {
  @Prop()
  payload!: any;

  isEditingJob = false;
  
  dateMenu = false;
  scheduledTimeMenu= false;
  scheduled_start_time = this.formatTime(this.payload.job.created_at);
  loadingInstallationPrimaryItems = true;

  jobModel = {
   created_at: this.formatDate(this.payload.job.created_at),
   cost: this.payload.job.cost,
   cost_disabled: this.payload.job.cost_disabled,
   is_complete: this.payload.job.is_complete,
   notes: this.payload.job.notes || '',
   id: this.payload.job.id,
   installation_id: this.payload.job.installation_id,
   partner_id: this.payload.job.partner_id || '',
   installation_item_id: this.getItemText(this.payload.job.installation_item_id),
   description: this.payload.job.description || '',
  }

  costRule:any =[]
  scheduledTimeRule = [
    (val: any) => !!val || this.$i18n.t("scheduled_time_required"),
  ]

  jobDescriptionRule = [
    (val: any) => val.length <= 80 || this.$i18n.t("installationDescriptionLengthRule"),
  ]

  get costDisabled() {
    return this.jobModel.cost_disabled == 1 ? true : false
  }

  get installtionPrimaryItems() {
    return itemsModule.installationPrimaryItems;
  }

  get isLoadingInstallationPrimaryItems() {
    return this.loadingInstallationPrimaryItems;
  }

  get disablePrimaryItems() {
    return this.loadingInstallationPrimaryItems || this.installtionPrimaryItems.length === 0;
  }


  @Watch('jobModel.cost')
    onActualCostChange(val: string, oldVal: string) {
      this.costRule = [];
      if (!val) {
        this.costRule.push((val: any) => !!val || this.$i18n.t("estimated_cost_required"))
      }
      this.costRule.push((val: any) => val >= parseInt(this.payload.job.cost) || val >= parseInt(this.payload.job.amount_paid) || this.$i18n.t("actionCostRule"));
  }

  async hideModal () {
    commonModule.hideModal();
  }

  async mounted() {
    commonModule.initSnackbar({});
    await commonModule.getPersonnelCombo();
    await commonModule.getActionCombo();
    await commonModule.getDurationCombo();
    await itemsModule.getInstallationPrimaryInventoryItems(this.payload.job.installation_id);
    this.jobModel.installation_item_id = this.getItemText(this.payload.job.installation_item_id)
    this.loadingInstallationPrimaryItems = false;
  }

  onlyDecimalNumbers(evt: any) {
    isDecimalNumber(evt);
  }

  formatDate (date: Date): string {
    return TimeUtil.formatDateOnly(date)
  }

  formatTime(date: Date): string {
    return TimeUtil.formatTimeOnly(date)
  }

  getItemText(ids: number) {
    const idArray = Array.isArray(ids) ? ids : [ids];
    const arr: any = itemsModule.allCompanyPrimaryItems

    for (let i = 0; i < arr.length; i++) {
      if ((Array.isArray(arr[i].id) && arr[i].id.some((item: any) => idArray.includes(item))) || (!Array.isArray(arr[i].id) && idArray.includes(arr[i].id))) {
       (itemsModule.installationPrimaryItems as any).push({ primary_items: arr[i].primary_items, id: ids })
        return { primary_items: arr[i].primary_items, id: ids };
      }
    }
    return { primary_items: '', id: 0 };
  }

  async doEditJob () {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        this.isEditingJob = true;
        this.jobModel.created_at = dayjs(this.jobModel.created_at + " " + this.scheduled_start_time).utc().format("DD MMM YYYY HH:mm:ss");
        await jobsModule.editJob(this.jobModel);
        this.jobModel.created_at = this.formatDate(this.payload.job.created_at); // Format created_at again because of RangeError warning
        jobsModule.getJobs(jobsModule.filters);
        this.hideModal()
    }  
  }
  get descriptionIsEnabled() {
    return (companyModule.company?.preferences as any)?.job_description;
  }
};
