import { BaseApi } from '@/helpers/base-api'

export default class AppointmentApi extends BaseApi {
  constructor () {
    super('/api/v1/appointments')
    this.errorMessage = 'There was an issue when trying to fetch the appointments. If this error persists, please contact our support.'
  }

  public getAllAppointments = (query: string): Promise<AppointmentDto[]> => this.instance.get(`${query}`)
  public getCalendarAppointments = (query: string): Promise<AppointmentDto[]> => this.instance.get(`/calendar${query}`)
  
  public getCalendarAppointmentsExport = (data: Record<string, unknown>): Promise<Blob> => this.instance.post(`/calendar/export`, data, { responseType: 'blob' })
  public getAppointmentsPdfExport = (query: string, data: Record<string, unknown>): Promise<Blob> => this.instance.post(`/export${query}`, data, { responseType: 'blob' })
  public createAppointment = (data: Record<string, unknown>): Promise<JSON> => this.instance.post('', data)

  public updateAppointment = (appointmentId: string, data: Record<string, unknown>): Promise<JSON> => this.instance.patch(`/${appointmentId}`, data)

  public cancelAppointment = (appointmentId: string): Promise<JSON> => this.instance.patch(`/${appointmentId}/cancel`)
  public endAppointment = (appointmentId: string, data: Record<string, unknown>): Promise<JSON> => this.instance.patch(`/${appointmentId}/end`, data)

  public deleteAppointment = (appointmentId: string): Promise<JSON> => this.instance.delete(`/${appointmentId}`)

}
