
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { TimeUtil } from '@/helpers/TimeUtil'
import { commonModule, itemsModule } from '@/store/modules/store-accessor'

import InstallationsItems from '@/components/Expansions/InstallationsItems.vue'

@Component({
  components: {
    InstallationsItems,
  },
})
export default class InstallationsSideviewItemsTab extends Vue {
 @Prop() installation: Record<string, any>;

  mounted() {
    commonModule.getPersonnelCombo();
    commonModule.getActionCombo();
    itemsModule.getAllInventoryItems('');
    commonModule.getFuelTypeCombo();
    commonModule.getPlacementTypeCombo();
  }

  @Watch("installation")
  updateItems() {
    commonModule.getPersonnelCombo();
    commonModule.getActionCombo();
    itemsModule.getAllInventoryItems('');
    commonModule.getFuelTypeCombo();
    commonModule.getPlacementTypeCombo();
  }

  get inactiveItems() {
    const inActiveItems = this.installation.items.filter((installation: any) => installation.is_active == false);
    return inActiveItems.sort((a: any, b: any) => {
          const c: any = new Date(a.created_at);
          const d: any = new Date(b.created_at);
          return d - c;
    });
  }

  get activeItems() {
    const activeItems = this.installation.items.filter((installation: any) => installation.is_active == true);
    return activeItems.sort((a: any, b: any) => {
          const c: any = new Date(a.created_at);
          const d: any = new Date(b.created_at);
          return d - c;
    });
  }

  get activeItemsExist() {
    return this.activeItems.length > 0
  }

  get inActiveItemsExist() {
    return this.inactiveItems.length > 0
  }

  formatDate (date: Date): string {
    return TimeUtil.formatDate(date)
  }

  addInstallationItem(){
    commonModule.showModal({ name: 'add-installation-item-modal', payload: { installation: this.installation }})
  }


  editInstallationItem (item: any){
    commonModule.showModal({ name: 'edit-installation-item-modal', payload: { installation_item: item, installation: this.installation }})
  }

  deleteInstallationItem (item: any){
    commonModule.showModal({ name: 'delete-installation-item-modal', payload: { installation_item: item, installation: this.installation }})
  }

};
