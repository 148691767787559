import {
  VuexModule,
  Module,
  Action,
  Mutation,
} from "vuex-module-decorators";
import ActionTypesApi from "@/api/actionTypes-api";
import dayjs from 'dayjs';
import { periodOptionToNumber, periodOptionToComboText, periodOptionToComboValue } from "@/helpers/data";

@Module({ namespaced: true, name: "actionTypes" })
export default class ActionTypesModule extends VuexModule {
  public actionTypesCombo: any = []
  public actionTypes = []
  public actionTypeById: ActionsTypeDto[] = [];
  public isLoadingTable = true;


  @Action
  async addActionType(data: Record<string, any>) {
    const actionTypesApi = new ActionTypesApi()
    if(data.cost == undefined || data.cost == null || data.cost == '') {
      delete data.cost;
    }
    delete data.periodic_option_combo;
    delete data.reminder_option_combo;
    const response = await actionTypesApi.createActionType(data)
    return response;
  }

  @Action({ commit: 'setActionTypesCombo'})
  async getActionTypesCombo (): Promise<any[]> {
    const actionTypesApi = new ActionTypesApi()
    const actionTypesCombo = await actionTypesApi.actionTypesCombo();
    return actionTypesCombo
  }

  @Action({ commit: 'setActionTypes'})
  async getAllActionTypes (): Promise<any[]> {
    const actionTypesApi = new ActionTypesApi()
    const actionTypes = await actionTypesApi.getAllActionTypesCombo();
    return actionTypes
  }

  @Action({ commit: "setActionTypeById" })
  async getActionTypeById(actionTypeId: string): Promise<ActionsTypeDto[]> {
    const actionTypesApi = new ActionTypesApi();

    const actionType = await actionTypesApi.getActionTypeById(actionTypeId);

    return actionType;
  }

  @Action
  async editActionType(data: Record<string, any>) {
    const actionTypesApi = new ActionTypesApi()
    if(data.cost == undefined || data.cost == null || data.cost == '') {
      data.cost = 0;
    }
    delete data.periodic_option_combo;
    delete data.reminder_option_combo;
    await actionTypesApi.updateActionType(data.id, data);
  }

  @Action
  async deleteActionType(data: Record<string, any>) {

    const actionTypesApi = new ActionTypesApi()
    await actionTypesApi.deleteActionType(data.id)
  }

  @Mutation
  public setActionTypesCombo (actionTypesCombo: any[]): void {
    actionTypesCombo.map(item => {
      item.next_task =  item.is_periodic ? dayjs().startOf('day').add(item.periodic_days, 'days').format('YYYY-MM-DD') : null
      item.reminder_option_value =  item.is_periodic ? periodOptionToNumber(item.reminder_option) : null
      item.reminder_option_combo =  item.is_periodic ? { text: periodOptionToComboText(item.reminder_option), period: periodOptionToComboValue(item.reminder_option) } : null
    })
    this.actionTypesCombo = actionTypesCombo
  }

  @Mutation
  public setActionTypeById (actionTypeById: ActionsTypeDto[]): void {
    this.actionTypeById = actionTypeById;
  }

  @Mutation
  public setActionTypes (actionTypes: []): void {
    this.actionTypes = actionTypes;
  }

  @Mutation
  public setIsLoadingTable(isLoadingTable: boolean): void {
    this.isLoadingTable = isLoadingTable
  }
}
