import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import PartnersApi from '@/api/partners-api';
import ComboApi from '@/api/combo-api';

@Module({ namespaced: true, name: "partners"})
export default class PartnersModule extends VuexModule {
    public allPartners = [];
    public currentPartner: PartnerDetailsDto = {} as PartnerDetailsDto;
    public isLoadingSideView = true;

    public isLoadingTable = true;

    @Action({ commit: 'setAllPartnersAction'})
    async getAllPartners (): Promise<any[]> {
      const partnersApi = new PartnersApi()
      const allPartners = await partnersApi.getAllPartners();
      return allPartners
    }

    @Action
    async addPartner(data: Record<string, any>) {
      this.setIsLoadingTable(true);
      const partnersApi = new PartnersApi();
      const newPartnerData = await partnersApi.createPartner(data);
      await this.getAllPartners();
      this.setIsLoadingTable(false);
  
      return newPartnerData;
    }

    @Action({ commit: 'setCurrentPartner'})
    async getPartnersInfo (currentPartnerId: string): Promise<PartnerDetailsDto> {
  
      const partnersApi = new PartnersApi()
      const partnerDetails = await partnersApi.getPartner(currentPartnerId)
      return partnerDetails
    }

    @Action
    async editPartner(data: Record<string, any>) {
      const partnersApi = new PartnersApi()
      await partnersApi.updatePartner(data.id, data)
      await this.getPartnersInfo(data.id);
      await this.getAllPartners();
    }  

    @Action
    async deletePartner(data: Record<string, any>) {
  
      const partnersApi = new PartnersApi();
      await partnersApi.deletePartner(data.id);
      await this.getAllPartners();
    }

    get mapJobsAndActions(): any[] {
      const newJobs = (this.currentPartner as any).jobs.map((job: any) => {
  
        let actions = (this.currentPartner as any).actions.filter((action: any) => {
          return action.job_id === job.id;
        });
  
        let materials = (this.currentPartner as any).materials.filter((materials: any) => {
          return materials.job_id === job.id;
        });
  
        let payments = (this.currentPartner as any).payments.filter((payment: any) => {
          return payment.job_id === job.id;
        });
  
        let expenses = (this.currentPartner as any).expenses.filter((expense: any) => {
          return expense.job_id === job.id;
        });

        actions = actions.map((item: any) => {
          const newItem = item;
          
          newItem.entry_type = 'action';
          return newItem;
        })
  
        materials = materials.map((item:any) => {
          const newItem = item;
          
          newItem.entry_type = 'material';
          return newItem;
        })
  
        payments = payments.map((item: any) => {
          const newItem = item;
          
          newItem.entry_type = 'payment';
          return newItem;
        })
  
        expenses = expenses.map((item: any) => {
          const newItem = item;
          
          newItem.entry_type = 'expense';
          return newItem;
        })
       
        const actionsAndPayments: Record<string, any>[] = ((actions as []).concat(materials as [])).concat(payments as []).concat(expenses as []);
  
        actionsAndPayments.sort((a, b) => {
          const c: any = new Date(a.created_at);
          const d: any = new Date(b.created_at);
          return c - d;
        });
        
        job.actions = [];
        job.actions = actionsAndPayments;
  
        return job;
      });
  
      return newJobs.filter((item: any) => {
        return (item.actions.length > 0)
      })
    }

    @Mutation
    public setAllPartnersAction (allPartners: []): void {
      try {
        (allPartners as any).map((item: PartnerDto) => {
          item.fullname = `${item.firstname} ${item.lastname}`
        })
        this.allPartners = allPartners;
        this.isLoadingTable = false;
      } catch (error) {
        this.isLoadingTable = false;
      }
    }

    @Mutation
    public setIsLoadingTable(isLoadingTable: boolean): void {
      this.isLoadingTable = isLoadingTable
    }

    @Mutation
    public setCurrentPartner (partner: PartnerDetailsDto): void {
      try {
        this.currentPartner = partner;
        partner.pending_tasks = partner.tasks.filter(x => x.task_status_id == 2)
        partner.tasks = partner.tasks.filter(x => x.task_status_id != 2)
        this.isLoadingTable = false;
        this.isLoadingSideView = false;
      } catch (error) {
        this.isLoadingTable = false;
        this.isLoadingSideView = false;
      }
    }

    @Mutation
    public setIsLoadingSideView(isLoadingSideView: boolean): void {
      this.isLoadingSideView = isLoadingSideView
    }

    @Mutation
    public unsetCurrentPartner(): void {
      this.currentPartner = {} as PartnerDetailsDto;
    }
}
