
  import { Component, Vue } from 'vue-property-decorator'
  import { commonModule, appointmentsModule, installationsModule, filterModule, itemsModule, companyModule } from '@/store/modules/store-accessor'
  import { calcDuration, appointmentStatusToColor, appointmentStatusToName, formatDateWithoutSeconds, sortTableDates, getOptimalTextColor, primaryItemText, pagesExportLimit, templatesFilenameLanguage } from "../helpers/data";
  import AppointmentsFilter from '../components/Filter/AppointmentsFilter.vue'
  import dayjs from 'dayjs';
  import FileSaver from 'file-saver';

  @Component({
    components: { AppointmentsFilter }
  })
  export default class Appointments extends Vue {

    tableheaders: any [] = [
    { text: this.$i18n.t("tag"), align: "start", value: "tag_id" },
    { text: this.$i18n.t("client"), value: "client_name" },
    { text: this.$i18n.t("type"), value: "type_name" },
    { text: this.$i18n.t("user"), value: "assignee_id" },
    { text: this.$i18n.t("item"), value: "installation_item_id" },
    { text: this.$i18n.t("address"), value: "address" },
    { text: this.$i18n.t("area"), value: "area" },
    { text: this.$i18n.t("scheduled_at"), value: "scheduled_start", sort: (a: any, b: any) => sortTableDates(a, b) },
    { text: this.$i18n.t("projected_duration"), value: "projected_duration" },
    { text: this.$i18n.t("status"), value: "appointment_status_id" },
    ]

    model: any = {
      search: '',
      selected: [] as string[]
    }

    isDownloadingAppointmentsPdf = false;

    get appointmentsLength() {
      return appointmentsModule.appointments.length
    }

    get searchLabel() {
      return this.$t("appointmentsSearch")
    }

    get appointments(){
      return appointmentsModule.appointments
    }

    get isLoadingTable() {
        return appointmentsModule.isLoadingTable;
    }

    optimalTextColor(color: string) {
      return getOptimalTextColor(color);
    }

    setDuration(start: Date, end: Date) {
        return calcDuration(start, end).includes('NaN') ? '-' : calcDuration(start, end);
    }

    setStatusColor(status: string) {
        return appointmentStatusToColor(status);
    }

    setStatusText(status: string) {
        return appointmentStatusToName(status);
    }

    setScheduledStart(scheduled_start: Date) {
        return formatDateWithoutSeconds(scheduled_start)
    }
  
    async mounted () {
        appointmentsModule.setSearch('');
        appointmentsModule.setQueryObject({});

        appointmentsModule.setIsLoadingTable(true);

        const dateFrom = dayjs().startOf('day').toISOString();
        const dateTo = dayjs().endOf('day').toISOString()

        appointmentsModule.queryObject.date_from = dateFrom;
        appointmentsModule.queryObject.date_to = dateTo;
        appointmentsModule.setAppointmentsFilter(`?date_from=${dateFrom}&date_to=${dateTo}`);

        await appointmentsModule.getAppointmentsAction(appointmentsModule.appointmentsFilters);
        await itemsModule.getAllCompanyPrimaryInventoryItems();
        appointmentsModule.setIsLoadingTable(false);
        commonModule.initSnackbar({});

        if((companyModule.company?.preferences as any)?.job_description) {
          this.tableheaders.splice(1, 0, { text: this.$t('description'), sortable: true, class: 'row-style', value: 'description' });
        }
    }

    destroyed() {
      appointmentsModule.clearAppointments();
    }
  
    async openInstallationSideView (appointment: any) {
      try {
        appointmentsModule.setIsLoadingTable(true);
        await installationsModule.getInstallationInfo(appointment.installation_id);
        appointmentsModule.setIsLoadingTable(false);
        commonModule.showSideview({ name: 'installations-sideview', payload: { installationId: appointment.installation_id, tab: 2, appointmentId: appointment.id } });
      } catch (error) {
        appointmentsModule.setIsLoadingTable(false);
      }
    }

    getItemText(id: number) {
      return primaryItemText(id, itemsModule.allCompanyPrimaryItems);
  }

    async refreshData() {
      await appointmentsModule.getAppointmentsAction(appointmentsModule.appointmentsFilters);
      await filterModule.getAppointmentsFilters();
    }

    timer: number;

   onSearchChange(val: string) {
    clearTimeout(this.timer)

    this.timer = setTimeout(async () => {
      appointmentsModule.setIsLoadingTable(true);
      if (val && val.length > 0) {
        appointmentsModule.setSearch(val);
        appointmentsModule.queryObject.search = appointmentsModule.search;
      }
      else {
        appointmentsModule.setSearch('');
        delete appointmentsModule.queryObject.search;
      }
      this.$router.replace({ query: appointmentsModule.queryObject });

      // Check if user selected any of the queries
      const queryIndex = this.$route.fullPath.indexOf('?');
      if (queryIndex >= 0) {
        const query = this.$route.fullPath.substring(queryIndex);
        appointmentsModule.setAppointmentsFilter(query);
      } else {
        appointmentsModule.setAppointmentsFilter('');
      }

      await appointmentsModule.getAppointmentsAction(appointmentsModule.appointmentsFilters);
      appointmentsModule.setIsLoadingTable(false);
    }, 500);
  }

  downloadPdfLengthCheck() {
    return this.appointmentsLength > 0 && this.appointmentsLength <= pagesExportLimit();
  }

  async donwloadAppointmentsPdf() {
    this.isDownloadingAppointmentsPdf = true;
    const utcOffset = dayjs().utcOffset();

    const data = { 'utcOffset': utcOffset, 'language': localStorage.getItem('lang') };
    
    const company = await companyModule.getCompanyInfo();
    const filename = `${templatesFilenameLanguage()}_${company.entity_name}_DfB_appointments_${dayjs().format('YYYY_MM_DD_HH_mm_ss')}.pdf`.replace(/ /g, '_');
    const pdf = await  appointmentsModule.getAppointmentsPdfExportAction({ query: appointmentsModule.appointmentsFilters, data: data });
    const file = new File([pdf], filename);
    FileSaver.saveAs(file);

    this.isDownloadingAppointmentsPdf = false;
  }

  }
