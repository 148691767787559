
  import Vue from "vue";
  import { Component, Prop, Emit, Watch } from "vue-property-decorator";
  import { formatDatesRangeText, sortDateRange } from "@/helpers/data";
  import KeycloakSession from "@/helpers/keycloak-session";
  
  @Component
  export default class DatePickerFilter extends Vue {
    @Prop({ type: Array, default: () => [new Date().toISOString().slice(0, 10), new Date().toISOString().slice(0, 10)] } /* Today */) dateRange!: any;
    menu = false;
    dates: any = this.dateRange;
    dateRangeText: any = formatDatesRangeText(this.dates[0], this.dates[1]);

  @Watch("dates")
  selectFilter() {
    this.$emit('update-filter', this.dates);
    this.dates = this.isEditorInPayments() ? this.dates.split() : this.dates;

    if(this.isEditorInPayments()) {
      if(this.dates == null){
        formatDatesRangeText(null, null);
      }
      else if(this.dates.length === 1) {
        this.isAdmin() ? this.dateRangeText = formatDatesRangeText(this.dates[0], null) : this.dateRangeText = formatDatesRangeText(this.dates, this.dates);
      }
      else if(this.dates.length === 2 && this.isAdmin()) {
        sortDateRange(this.dates);
        this.dateRangeText = formatDatesRangeText(this.dates[0], this.dates[1])
      }
    } else {
      if(this.dates == null){
        formatDatesRangeText(null, null);
      }
      else if(this.dates.length === 1) {
        this.dateRangeText = formatDatesRangeText(this.dates[0], null)
      }
      else if(this.dates.length === 2) {
        sortDateRange(this.dates);
        this.dateRangeText = formatDatesRangeText(this.dates[0], this.dates[1])
      }
    }
  }

  isEditorInPayments() {
    const kc = new KeycloakSession()
    if(kc.userHasRole('editor') && !kc.userHasRole('admin') && this.$route.name === 'My Company') {
      return true;
    } else {
      return false;
    }
  }

  isAdmin () {
    const kc = new KeycloakSession()
    if(kc.userHasRole('admin')) {
      return true;
    }
    else if (kc.userHasRole('editor')) {
      return false;
    }
  }

  clearDateRange() {
    this.dates = null
    this.dateRangeText = ''
  }

  closeMenu() {
    this.menu = false;
  }

  }

  